//@ts-nocheck

import React, { useEffect } from "react";
import { Route, Router, Routes, useNavigate } from "react-router-dom";
import LoadingSpinner from "./components/LoadingSpinner";

// pages

const Videos = React.lazy(() => import("./pages/index"));
const ErrorPage = React.lazy(() => import("./pages/_errorPage"));
const Login = React.lazy(() => import("./pages/Login"));
const Prestataires = React.lazy(() => import("./pages/prestataires/index"));
const Patient = React.lazy(() => import("./pages/patient"));
const Demands = React.lazy(() => import("./pages/Demands/Demands"));
const PrestatairesInfo = React.lazy(() => import("./pages/prestataires/PrestatairesInfo"));
const PatientsInfo = React.lazy(() => import("./pages/patient/PatientsInfo"));
const AdminInfo = React.lazy(() => import("./pages/account/Admininfo"));
const AddStaff = React.lazy(() => import("./pages/account/addStaff"));
const Active = React.lazy(() => import("./pages/active"));
const UpdatePrestatireInfo = React.lazy(() => import("./pages/prestataires/updateInfo"));
const FullDemandeInfo = React.lazy(() => import("./pages/Demands/FullDemandeInfo"));
const Messages = React.lazy(() => import("./pages/messages/index"));
const CouponsPage = React.lazy(() => import("./pages/coupons/index"));

function App(props: any) {
  // props
  const {} = props;

  return (
    <div className="App">
      <React.Suspense fallback={<LoadingSpinner />}>
        <Routes>
          {/* pages with layout */}

          <Route path="/">
            <Route index element={<Videos />} />
            <Route path="/prestataires" element={<Prestataires />} />
            <Route path="/prestataires/info/:id" element={<PrestatairesInfo />} />
            <Route path="/patient" element={<Patient />} />
            <Route path="/patient/info/:id" element={<PatientsInfo />} />
            <Route path="/demands" element={<Demands />} />
            <Route path="/account/:id" element={<AdminInfo />} />
            <Route path="/account/addstaff" element={<AddStaff />} />
            <Route path="/prestataires/update/:id" element={<UpdatePrestatireInfo />} />
            <Route path="/demande/info/:id" element={<FullDemandeInfo />} />
            <Route path="/messages" element={<Messages />} />
            <Route path="/coupons" element={<CouponsPage />} />
          </Route>
          {/* error page */}
          <Route path="*" element={<ErrorPage />} />

          {/* login page */}
          <Route path="/login" element={<Login />} />
          <Route path="/active/:token" element={<Active />} />
        </Routes>
      </React.Suspense>
    </div>
  );
}

export default App;
