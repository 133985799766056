import React, { useState } from "react";
import LoadingSpinner from "../components/LoadingSpinner";

interface LoadingContext {
  isLoading: boolean;
  setIsLoading: any;
}

export const LoadingContext = React.createContext<LoadingContext>({ isLoading: false, setIsLoading: null });

const LoadingProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      {isLoading && <LoadingSpinner />}
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
