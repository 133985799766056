import { useLocalStorage } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAdminLogoutMutation, useLoginAdminMutation } from "../generated/apolloComponents";
import { setAccessToken, getAccessToken } from "../utils/accessToken";
import JwtDecode from "jwt-decode";
import { useLoading } from "../hooks/useLoading";
import { API_URL } from "../constant/api";
import { useApolloNetworkStatus } from "../utils/ApolloClient";
import { message } from "antd";

interface AuthContextType {
  login: (authInfo: AuthInfo, callback: VoidFunction) => void;
  logout: (callback: VoidFunction) => void;
  user: UserInfo | null;
}

interface AuthInfo {
  email: string;
  password: string;
}

interface UserInfo {
  currentUser: { email: string; first_name: string; last_name: string; role: string; permissions: string[] };
  exp: number;
  iat: number;
  id: string;
}

export let AuthContext = React.createContext<AuthContextType>({ login: () => {}, logout: () => {}, user: null });

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = useLocalStorage({ key: "token", defaultValue: null });
  const [user, setUser] = useState<UserInfo | null>(null);
  const [loginMutation, { data, loading, error }] = useLoginAdminMutation();
  const [logoutMutation, { data: logoutData, error: logoutError, loading: logoutLoading }] = useAdminLogoutMutation();
  const { setIsLoading } = useLoading();
  const location = useLocation();
  const [messageApi, contextHolder] = message.useMessage();

  const status = useApolloNetworkStatus();

  console.log(status);

  let login = async (authInfo: AuthInfo, callback: VoidFunction) => {
    setIsLoading(true);
    loginMutation({
      variables: {
        data: authInfo,
      },
    })
      .then(() => {
        setIsLoading(true);
        fetch(`${API_URL}user/refresh_token`, { credentials: "include", method: "GET" })
          .then(res => {
            if (res.ok) {
              setIsLoading(false);
              return res.json();
            }

            if (location.pathname === `/active/${location.pathname.split("/")[2]}`) {
              setIsLoading(false);
              return;
            }

            navigate("/login");
            setIsLoading(false);
          })
          .then(token => {
            setAccessToken(token.accesToken);
            const data = JwtDecode(token.accesToken) as UserInfo;
            setUser(data);
            setIsLoading(false);
          })
          .catch(err => {
            console.log(err);
            navigate("/login");
            setIsLoading(false);
          });
        callback();
      })
      .catch(() => {
        messageApi.info("your password or email not correct");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  let logout = (callback: VoidFunction) => {
    logoutMutation().then(() => {
      setUser(null);
      callback();
    });
  };

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    fetch(`${API_URL}user/refresh_token`, { credentials: "include", method: "GET" })
      .then(res => {
        if (res.ok) {
          setIsLoading(false);
          return res.json();
        }

        if (location.pathname === `/active/${location.pathname.split("/")[2]}`) {
          setIsLoading(false);
          return;
        }

        setIsLoading(false);
        navigate("/login");
      })
      .then(token => {
        setAccessToken(token.accesToken);
        const data = JwtDecode(token.accesToken) as UserInfo;
        setUser(data);
      })
      .catch(err => {
        console.log(err);
        if (location.pathname === `/active/${location.pathname.split("/")[2]}`) {
          setIsLoading(false);
          return;
        }
        navigate("/login");
        setIsLoading(false);
      });
  }, []);

  console.log(user);

  const value = { login, logout, user };

  return (
    <AuthContext.Provider value={value}>
      {contextHolder}
      {children}
    </AuthContext.Provider>
  );
}
