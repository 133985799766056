import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Upload: any;
};

export type AccountInfoType = {
  __typename?: 'AccountInfoType';
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
};

export type AddAccountAdminArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  password: Scalars['String'];
  permissions: Array<Scalars['String']>;
  role: Scalars['String'];
};

export type AddAccountStaffArgs = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  permissions: Array<Scalars['String']>;
  role: Scalars['String'];
};

export type Admin = {
  __typename?: 'Admin';
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  role: Scalars['String'];
};

export type AdminDemands = {
  __typename?: 'AdminDemands';
  AppliedDiscountAmount?: Maybe<Scalars['Float']>;
  AppliedDiscountPercentage?: Maybe<Scalars['Float']>;
  FraisServicePatient: Scalars['Float'];
  FraisServicePrestataire: Scalars['Float'];
  PourcentageServicePatient: Scalars['Float'];
  PourcentageServicePrestataire: Scalars['Float'];
  Review?: Maybe<Scalars['String']>;
  adressePatient: Scalars['String'];
  becareCoupon?: Maybe<Coupons>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  cashPlusCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateSeance: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isPayed?: Maybe<Scalars['Boolean']>;
  latitudePatient?: Maybe<Scalars['Float']>;
  longitudePatient?: Maybe<Scalars['Float']>;
  modePaiment: Scalars['String'];
  montant: Scalars['Float'];
  nbrSeance: Scalars['Float'];
  offerSericeId?: Maybe<OffreService>;
  patientId: Patient;
  patientInvoiceNumber?: Maybe<Scalars['Float']>;
  patientInvoicePath?: Maybe<Scalars['String']>;
  prestataireId: Prestataire;
  prestation: Array<Prestation>;
  quantite: Scalars['String'];
  status: Scalars['String'];
  statusDemande: Array<DemandeStatus>;
};

export type CalCulationRes = {
  __typename?: 'CalCulationRes';
  TotalPourPaye?: Maybe<Scalars['Float']>;
  becareCoupons?: Maybe<Scalars['Float']>;
  isDiscountApplied?: Maybe<Scalars['Boolean']>;
  remiseApplied?: Maybe<Scalars['Float']>;
  seancePrix?: Maybe<Scalars['Float']>;
  seancePrixAvecDiscount?: Maybe<Scalars['Float']>;
  totalSeance?: Maybe<Scalars['Float']>;
};

export type CardInfoInput = {
  cardHolderName: Scalars['String'];
  creditCard: Scalars['String'];
  cvv: Scalars['Float'];
  expireDate: Scalars['String'];
};

export type CouponUserStats = {
  __typename?: 'CouponUserStats';
  activeCoupons?: Maybe<Scalars['Float']>;
  coupons?: Maybe<Array<Coupons>>;
  expriedCoupons?: Maybe<Scalars['Float']>;
  totalUsedTimes?: Maybe<Scalars['Float']>;
};

export type CouponWithAdmin = {
  __typename?: 'CouponWithAdmin';
  admin?: Maybe<Admin>;
  adminId?: Maybe<Scalars['String']>;
  coupon_name?: Maybe<Scalars['String']>;
  coupons_code?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  fixedAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  percentAmount?: Maybe<Scalars['Float']>;
  start_date?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  used_times?: Maybe<Scalars['Float']>;
};

export type Coupons = {
  __typename?: 'Coupons';
  adminId?: Maybe<Scalars['String']>;
  coupon_name?: Maybe<Scalars['String']>;
  coupons_code?: Maybe<Scalars['String']>;
  end_date?: Maybe<Scalars['String']>;
  fixedAmount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['String']>;
  percentAmount?: Maybe<Scalars['Float']>;
  start_date?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  used_times?: Maybe<Scalars['Float']>;
};

export type CouponsUser = {
  __typename?: 'CouponsUser';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type CraetecouponInputs = {
  adminId?: InputMaybe<Scalars['String']>;
  coupon_name?: InputMaybe<Scalars['String']>;
  coupons_code?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['String']>;
  fixedAmount?: InputMaybe<Scalars['Float']>;
  percentAmount?: InputMaybe<Scalars['Float']>;
  start_date?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type Demande = {
  __typename?: 'Demande';
  AppliedDiscountAmount?: Maybe<Scalars['Float']>;
  AppliedDiscountPercentage?: Maybe<Scalars['Float']>;
  FraisServicePatient: Scalars['Float'];
  FraisServicePrestataire: Scalars['Float'];
  PourcentageServicePatient: Scalars['Float'];
  PourcentageServicePrestataire: Scalars['Float'];
  Review?: Maybe<Scalars['String']>;
  adressePatient: Scalars['String'];
  becareCoupon?: Maybe<Coupons>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  cashPlusCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateSeance: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isPayed?: Maybe<Scalars['Boolean']>;
  latitudePatient?: Maybe<Scalars['Float']>;
  longitudePatient?: Maybe<Scalars['Float']>;
  modePaiment: Scalars['String'];
  montant: Scalars['Float'];
  nbrSeance: Scalars['Float'];
  offerSericeId?: Maybe<OffreService>;
  patientInvoiceNumber?: Maybe<Scalars['Float']>;
  patientInvoicePath?: Maybe<Scalars['String']>;
  prestation: Array<Prestation>;
  quantite: Scalars['String'];
  status: Scalars['String'];
  statusDemande: Array<DemandeStatus>;
};

export type DemandeForAffiliate = {
  __typename?: 'DemandeForAffiliate';
  AppliedDiscountAmount?: Maybe<Scalars['Float']>;
  AppliedDiscountPercentage?: Maybe<Scalars['Float']>;
  FraisServicePatient: Scalars['Float'];
  FraisServicePrestataire: Scalars['Float'];
  PourcentageServicePatient: Scalars['Float'];
  PourcentageServicePrestataire: Scalars['Float'];
  Review?: Maybe<Scalars['String']>;
  adressePatient: Scalars['String'];
  affiliateAmount: Scalars['Float'];
  becareCoupon?: Maybe<Coupons>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  cashPlusCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateSeance: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isPayed?: Maybe<Scalars['Boolean']>;
  latitudePatient?: Maybe<Scalars['Float']>;
  longitudePatient?: Maybe<Scalars['Float']>;
  modePaiment: Scalars['String'];
  montant: Scalars['Float'];
  nbrSeance: Scalars['Float'];
  offerSericeId?: Maybe<OffreService>;
  patientInvoiceNumber?: Maybe<Scalars['Float']>;
  patientInvoicePath?: Maybe<Scalars['String']>;
  prestation: Array<Prestation>;
  quantite: Scalars['String'];
  status: Scalars['String'];
  statusDemande: Array<DemandeStatus>;
};

export type DemandeOption = {
  __typename?: 'DemandeOption';
  option: Scalars['String'];
  prix: Scalars['Float'];
};

export type DemandeSearchInput = {
  modePaiment?: InputMaybe<Scalars['String']>;
  patient_name?: InputMaybe<Scalars['String']>;
  prestataire_name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

export type DemandeStatus = {
  __typename?: 'DemandeStatus';
  Nom: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
};

export type DemandeWithPatient = {
  __typename?: 'DemandeWithPatient';
  AppliedDiscountAmount?: Maybe<Scalars['Float']>;
  AppliedDiscountPercentage?: Maybe<Scalars['Float']>;
  FraisServicePatient: Scalars['Float'];
  FraisServicePrestataire: Scalars['Float'];
  PourcentageServicePatient: Scalars['Float'];
  PourcentageServicePrestataire: Scalars['Float'];
  Review?: Maybe<Scalars['String']>;
  adressePatient: Scalars['String'];
  becareCoupon?: Maybe<Coupons>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  cashPlusCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateSeance: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isPayed?: Maybe<Scalars['Boolean']>;
  latitudePatient?: Maybe<Scalars['Float']>;
  longitudePatient?: Maybe<Scalars['Float']>;
  modePaiment: Scalars['String'];
  montant: Scalars['Float'];
  nbrSeance: Scalars['Float'];
  offerSericeId?: Maybe<OffreService>;
  patientId: PatientWithUser;
  patientInvoiceNumber?: Maybe<Scalars['Float']>;
  patientInvoicePath?: Maybe<Scalars['String']>;
  prestataireId: Scalars['String'];
  prestation: Array<Prestation>;
  quantite: Scalars['String'];
  status: Scalars['String'];
  statusDemande: Array<DemandeStatus>;
};

export type DemandeWithPrestataire = {
  __typename?: 'DemandeWithPrestataire';
  AppliedDiscountAmount?: Maybe<Scalars['Float']>;
  AppliedDiscountPercentage?: Maybe<Scalars['Float']>;
  FraisServicePatient: Scalars['Float'];
  FraisServicePrestataire: Scalars['Float'];
  PourcentageServicePatient: Scalars['Float'];
  PourcentageServicePrestataire: Scalars['Float'];
  Review?: Maybe<Scalars['String']>;
  adressePatient: Scalars['String'];
  becareCoupon?: Maybe<Coupons>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  cashPlusCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateSeance: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isPayed?: Maybe<Scalars['Boolean']>;
  latitudePatient?: Maybe<Scalars['Float']>;
  longitudePatient?: Maybe<Scalars['Float']>;
  modePaiment: Scalars['String'];
  montant: Scalars['Float'];
  nbrSeance: Scalars['Float'];
  offerSericeId?: Maybe<OffreService>;
  patientInvoiceNumber?: Maybe<Scalars['Float']>;
  patientInvoicePath?: Maybe<Scalars['String']>;
  prestataireId: PrestataireWithUser;
  prestation: Array<Prestation>;
  quantite: Scalars['String'];
  status: Scalars['String'];
  statusDemande: Array<DemandeStatus>;
};

export type DemandeWithPrestataireAndPatientAndofferSerice = {
  __typename?: 'DemandeWithPrestataireAndPatientAndofferSerice';
  AppliedDiscountAmount?: Maybe<Scalars['Float']>;
  AppliedDiscountPercentage?: Maybe<Scalars['Float']>;
  FraisServicePatient: Scalars['Float'];
  FraisServicePrestataire: Scalars['Float'];
  PourcentageServicePatient: Scalars['Float'];
  PourcentageServicePrestataire: Scalars['Float'];
  Review?: Maybe<Scalars['String']>;
  adressePatient: Scalars['String'];
  becareCoupon?: Maybe<Coupons>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  cashPlusCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateSeance: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isPayed?: Maybe<Scalars['Boolean']>;
  latitudePatient?: Maybe<Scalars['Float']>;
  longitudePatient?: Maybe<Scalars['Float']>;
  modePaiment: Scalars['String'];
  montant: Scalars['Float'];
  nbrSeance: Scalars['Float'];
  offerSerice: OffreService;
  offerSericeId?: Maybe<OffreService>;
  patientId: PatientWithUser;
  patientInvoiceNumber?: Maybe<Scalars['Float']>;
  patientInvoicePath?: Maybe<Scalars['String']>;
  prestataireId: PrestataireWithUser;
  prestation: Array<Prestation>;
  quantite: Scalars['String'];
  status: Scalars['String'];
  statusDemande: Array<DemandeStatus>;
};

export type DemandesForAffiliateStats = {
  __typename?: 'DemandesForAffiliateStats';
  totalAffiliateAmount: Scalars['Float'];
  totalAffiliateAmountAccepted: Scalars['Float'];
};

export type DiscountsOffreSrvice = {
  __typename?: 'DiscountsOffreSrvice';
  discount: Scalars['Float'];
  nmbr: Scalars['Float'];
};

export type DiscountsOffreSrviceInput = {
  discount: Scalars['Float'];
  nmbr: Scalars['Float'];
};

export type Disponibilite = {
  __typename?: 'Disponibilite';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  prestataireId: Scalars['String'];
  program: Array<AvailabilityTime>;
};

export type DisponibiliteArgs = {
  program: Array<AvailabilityTimeArgs>;
};

export type Localisation = {
  __typename?: 'Localisation';
  adresse: Scalars['String'];
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  patientId: Scalars['String'];
  secteur: Scalars['String'];
  ville: Scalars['String'];
};

export type LoginAdminArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type ManyDemandeInput = {
  adressePatient: Scalars['String'];
  becareCoupon?: InputMaybe<Scalars['String']>;
  cardInfo?: InputMaybe<CardInfoInput>;
  description: Scalars['String'];
  modePaiment: Scalars['String'];
  offerSericeId: Scalars['String'];
  seanceDate: Array<Scalars['DateTime']>;
};

export type MessageType = {
  __typename?: 'MessageType';
  _id: Scalars['String'];
  accountId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  isPrestataire: Scalars['Boolean'];
  isRegistred?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
  sender?: Maybe<Scalars['String']>;
  senderPhoneNumber: Scalars['String'];
  vue?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  UpdateDisponibilite: Disponibilite;
  UpdateOffre: OffreService;
  UpdatePatient: Patient;
  UpdatePrestataire: Prestataire;
  UpdateServiceAndOption: ServiceAndOptions;
  addAdminAccount: Scalars['Boolean'];
  addDisponibilite: Disponibilite;
  addLocation: Localisation;
  addManyDemande: AddManyDemandesResponse;
  addMultiOffre: Array<OffreService>;
  addNotification: Notification;
  addOffre: OffreService;
  addReview: Scalars['Boolean'];
  addSecteur: Array<Secteur>;
  addServiceAndOption: ServiceAndOptions;
  addStaffAccount: Scalars['Boolean'];
  addVille: Ville;
  adminlogout: Scalars['Boolean'];
  clearUserTokens: Scalars['Boolean'];
  createCoupon: Scalars['Boolean'];
  currentPrestataire: Scalars['Boolean'];
  deleteCoupon: Scalars['Boolean'];
  deleteDisponibilite: Scalars['Boolean'];
  deleteLocationById: Scalars['Boolean'];
  deleteOffre: Scalars['Boolean'];
  deletePrestataire: Scalars['Boolean'];
  deleteServiceAndOption: Scalars['Boolean'];
  deleteVilleById: Scalars['Boolean'];
  login: Scalars['String'];
  loginAdmin: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  sendNotication: Scalars['Boolean'];
  setAgadirSecteur: Array<Secteur>;
  setCasaBancaSecteur: Array<Secteur>;
  updateAccountInfo: AccountInfoType;
  updateCitiesDataBase: Array<Ville>;
  updateCoupon: Scalars['Boolean'];
  updateCurrentUserNotification: Scalars['Boolean'];
  updateDemandeStatus: DemandeWithPatient;
  updatePassword: Scalars['Boolean'];
  updatePrestatireInfoAdmin: Scalars['Boolean'];
  updatePrestatireStatus: Scalars['Boolean'];
  uploader: Scalars['String'];
  verfiyCode: Scalars['Boolean'];
};


export type MutationUpdateDisponibiliteArgs = {
  data: ProgrammeS;
  id: Scalars['String'];
};


export type MutationUpdateOffreArgs = {
  id: Scalars['String'];
  options: MyOptionw;
  prixService: Scalars['Float'];
};


export type MutationUpdatePatientArgs = {
  adresse?: InputMaybe<Scalars['String']>;
  cin?: InputMaybe<Scalars['String']>;
  dateNaissance?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  nom?: InputMaybe<Scalars['String']>;
  notificationParMail?: InputMaybe<Scalars['Boolean']>;
  notificationParSms?: InputMaybe<Scalars['Boolean']>;
  pay?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
  prenom?: InputMaybe<Scalars['String']>;
  sexe?: InputMaybe<Scalars['String']>;
  ville?: InputMaybe<Scalars['String']>;
};


export type MutationUpdatePrestataireArgs = {
  data: PrestataireType;
};


export type MutationUpdateServiceAndOptionArgs = {
  Nom: Scalars['String'];
  group: Scalars['String'];
  id: Scalars['String'];
  options?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationAddAdminAccountArgs = {
  data: AddAccountAdminArgs;
};


export type MutationAddDisponibiliteArgs = {
  data: DisponibiliteArgs;
};


export type MutationAddLocationArgs = {
  adresse: Scalars['String'];
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  secteur: Scalars['String'];
  ville: Scalars['String'];
};


export type MutationAddManyDemandeArgs = {
  data: ManyDemandeInput;
};


export type MutationAddMultiOffreArgs = {
  data: MultioffreServiceArgs;
};


export type MutationAddNotificationArgs = {
  data: NotificationInput;
};


export type MutationAddOffreArgs = {
  data: OffreServiceArgs;
};


export type MutationAddReviewArgs = {
  data: ReviewInput;
};


export type MutationAddSecteurArgs = {
  city_id: Scalars['String'];
  city_name: Scalars['String'];
};


export type MutationAddServiceAndOptionArgs = {
  Nom: Scalars['String'];
  group: Scalars['String'];
  options?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationAddStaffAccountArgs = {
  data: AddAccountStaffArgs;
};


export type MutationAddVilleArgs = {
  ville: Scalars['String'];
};


export type MutationClearUserTokensArgs = {
  userId: Scalars['String'];
};


export type MutationCreateCouponArgs = {
  data: CraetecouponInputs;
};


export type MutationCurrentPrestataireArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};


export type MutationDeleteCouponArgs = {
  id: Scalars['String'];
};


export type MutationDeleteDisponibiliteArgs = {
  id: Scalars['String'];
};


export type MutationDeleteLocationByIdArgs = {
  id: Scalars['String'];
};


export type MutationDeleteOffreArgs = {
  id: Scalars['String'];
};


export type MutationDeletePrestataireArgs = {
  id: Scalars['String'];
};


export type MutationDeleteServiceAndOptionArgs = {
  id: Scalars['String'];
};


export type MutationDeleteVilleByIdArgs = {
  secteurId: Scalars['String'];
};


export type MutationLoginArgs = {
  phone: Scalars['String'];
  type?: InputMaybe<Scalars['String']>;
};


export type MutationLoginAdminArgs = {
  data: LoginAdminArgs;
};


export type MutationSendNoticationArgs = {
  data?: InputMaybe<SendNotificationInput>;
};


export type MutationSetAgadirSecteurArgs = {
  id: Scalars['String'];
};


export type MutationSetCasaBancaSecteurArgs = {
  id: Scalars['String'];
};


export type MutationUpdateAccountInfoArgs = {
  data: UpdateAccountInfo;
};


export type MutationUpdateCouponArgs = {
  data: CraetecouponInputs;
  id: Scalars['String'];
};


export type MutationUpdateCurrentUserNotificationArgs = {
  notificationsIds: Array<Scalars['String']>;
};


export type MutationUpdateDemandeStatusArgs = {
  demandeId: Scalars['String'];
  status: Scalars['String'];
};


export type MutationUpdatePasswordArgs = {
  data: UpdateAccountPassword;
};


export type MutationUpdatePrestatireInfoAdminArgs = {
  data: UpdatePrestatireInfo;
  id: Scalars['String'];
};


export type MutationUpdatePrestatireStatusArgs = {
  prestataireId: Scalars['String'];
  status: Scalars['String'];
};


export type MutationUploaderArgs = {
  file: Scalars['Upload'];
  filename: Scalars['String'];
};


export type MutationVerfiyCodeArgs = {
  code: Scalars['Float'];
  telephone: Scalars['String'];
};

export type MyOption = {
  __typename?: 'MyOption';
  Prix: Scalars['Float'];
  id: Scalars['ID'];
  option: Scalars['String'];
};

export type MyOptiona = {
  Prix: Scalars['Float'];
  option: Scalars['String'];
};

export type MyOptionw = {
  optiona: Array<MyOptiona>;
};

export type OffresGroup = {
  __typename?: 'OffresGroup';
  groups: OffresGroupServices;
};

export type OffresGroupServices = {
  __typename?: 'OffresGroupServices';
  services: Array<OffreService>;
};

export type Patient = {
  __typename?: 'Patient';
  adresse?: Maybe<Scalars['String']>;
  cin?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateNaissance?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nbrDemandePasser: Scalars['String'];
  nom?: Maybe<Scalars['String']>;
  notificationParMail: Scalars['Boolean'];
  notificationParSms: Scalars['Boolean'];
  pay?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prenom?: Maybe<Scalars['String']>;
  sexe?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  ville?: Maybe<Scalars['String']>;
};

export type PatientSearchInput = {
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  nbrDemandePasser?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  ville?: InputMaybe<Scalars['String']>;
};

export type PatientStatus = {
  __typename?: 'PatientStatus';
  numberVerification: Scalars['Boolean'];
  profileCompletion?: Maybe<Scalars['Boolean']>;
  profileFillPercentage: Scalars['Float'];
  validAccount?: Maybe<Scalars['Boolean']>;
};

export type PatientWithAddresses = {
  __typename?: 'PatientWithAddresses';
  address: Array<Localisation>;
  adresse?: Maybe<Scalars['String']>;
  cin?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateNaissance?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nbrDemandePasser: Scalars['String'];
  nom?: Maybe<Scalars['String']>;
  notificationParMail: Scalars['Boolean'];
  notificationParSms: Scalars['Boolean'];
  pay?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  prenom?: Maybe<Scalars['String']>;
  sexe?: Maybe<Scalars['String']>;
  ville?: Maybe<Scalars['String']>;
};

export type PatientWithPhone = {
  __typename?: 'PatientWithPhone';
  adresse?: Maybe<Scalars['String']>;
  cin?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateNaissance?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nbrDemandePasser: Scalars['String'];
  nom?: Maybe<Scalars['String']>;
  notificationParMail: Scalars['Boolean'];
  notificationParSms: Scalars['Boolean'];
  pay?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  prenom?: Maybe<Scalars['String']>;
  sexe?: Maybe<Scalars['String']>;
  ville?: Maybe<Scalars['String']>;
};

export type PatientWithUser = {
  __typename?: 'PatientWithUser';
  adresse?: Maybe<Scalars['String']>;
  cin?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateNaissance?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nbrDemandePasser: Scalars['String'];
  nom?: Maybe<Scalars['String']>;
  notificationParMail: Scalars['Boolean'];
  notificationParSms: Scalars['Boolean'];
  pay?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  prenom?: Maybe<Scalars['String']>;
  sexe?: Maybe<Scalars['String']>;
  userId: User;
  ville?: Maybe<Scalars['String']>;
};

export type PhoneInput = {
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  texts?: InputMaybe<Array<Scalars['String']>>;
};

export type Prestataire = {
  __typename?: 'Prestataire';
  Balance?: Maybe<Scalars['Float']>;
  ContratChemin?: Maybe<Scalars['String']>;
  Dateinscription?: Maybe<Scalars['DateTime']>;
  Disponibilites?: Maybe<Scalars['String']>;
  EnsAdresse?: Maybe<Scalars['String']>;
  EnsICE?: Maybe<Scalars['String']>;
  FraisService: Scalars['Float'];
  InfirCin?: Maybe<Scalars['String']>;
  InfirCinChemin?: Maybe<Scalars['String']>;
  InfirDiplome?: Maybe<Scalars['String']>;
  InfirDiplomeChemin?: Maybe<Scalars['String']>;
  InfirPatente?: Maybe<Scalars['String']>;
  InfirPrenom?: Maybe<Scalars['String']>;
  InfirRib?: Maybe<Scalars['Float']>;
  InfirSexe?: Maybe<Scalars['String']>;
  Offres?: Maybe<Array<OffreService>>;
  Pays: Scalars['String'];
  Secteurs: Array<Secteur>;
  Type?: Maybe<Scalars['String']>;
  adresse?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateNaissance?: Maybe<Scalars['DateTime']>;
  dispo?: Maybe<Array<Disponibilite>>;
  email?: Maybe<Scalars['String']>;
  expoPushToken?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  infirExperiences?: Maybe<Scalars['DateTime']>;
  infirFonction?: Maybe<Scalars['String']>;
  infirRib?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  nbrDemandetraiter: Scalars['Float'];
  nbrReviews?: Maybe<Scalars['Float']>;
  nom?: Maybe<Scalars['String']>;
  notificationParMail: Scalars['Boolean'];
  notificationParSms: Scalars['Boolean'];
  photo?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  ville: Scalars['String'];
};

export type PrestataireLongLat = {
  __typename?: 'PrestataireLongLat';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type PrestataireType = {
  Balance?: InputMaybe<Scalars['Float']>;
  ContratChemin?: InputMaybe<Scalars['String']>;
  Dateinscription?: InputMaybe<Scalars['DateTime']>;
  Disponibilites?: InputMaybe<Scalars['String']>;
  EnsAdresse?: InputMaybe<Scalars['String']>;
  EnsICE?: InputMaybe<Scalars['String']>;
  FraisService?: InputMaybe<Scalars['Float']>;
  InfirCin?: InputMaybe<Scalars['String']>;
  InfirCinChemin?: InputMaybe<Scalars['String']>;
  InfirDiplome?: InputMaybe<Scalars['String']>;
  InfirDiplomeChemin?: InputMaybe<Scalars['String']>;
  InfirPatente?: InputMaybe<Scalars['String']>;
  InfirPrenom?: InputMaybe<Scalars['String']>;
  InfirSexe?: InputMaybe<Scalars['String']>;
  Offres?: InputMaybe<Array<Scalars['String']>>;
  Pays?: InputMaybe<Scalars['String']>;
  Secteurs?: InputMaybe<Array<Scalars['String']>>;
  Type?: InputMaybe<Scalars['String']>;
  adresse?: InputMaybe<Scalars['String']>;
  dateNaissance?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  expoPushToken?: InputMaybe<Scalars['String']>;
  infirExperiences?: InputMaybe<Scalars['DateTime']>;
  infirFonction?: InputMaybe<Scalars['String']>;
  infirRib?: InputMaybe<Scalars['Float']>;
  latitude?: InputMaybe<Scalars['Float']>;
  longitude?: InputMaybe<Scalars['Float']>;
  nbrDemandetraiter?: InputMaybe<Scalars['Float']>;
  nom?: InputMaybe<Scalars['String']>;
  notificationParMail?: InputMaybe<Scalars['Boolean']>;
  notificationParSms?: InputMaybe<Scalars['Boolean']>;
  photo?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  ville?: InputMaybe<Scalars['String']>;
};

export type PrestataireWithPhone = {
  __typename?: 'PrestataireWithPhone';
  Balance?: Maybe<Scalars['Float']>;
  ContratChemin?: Maybe<Scalars['String']>;
  Dateinscription?: Maybe<Scalars['DateTime']>;
  Disponibilites?: Maybe<Scalars['String']>;
  EnsAdresse?: Maybe<Scalars['String']>;
  EnsICE?: Maybe<Scalars['String']>;
  FraisService: Scalars['Float'];
  InfirCin?: Maybe<Scalars['String']>;
  InfirCinChemin?: Maybe<Scalars['String']>;
  InfirDiplome?: Maybe<Scalars['String']>;
  InfirDiplomeChemin?: Maybe<Scalars['String']>;
  InfirPatente?: Maybe<Scalars['String']>;
  InfirPrenom?: Maybe<Scalars['String']>;
  InfirRib?: Maybe<Scalars['Float']>;
  InfirSexe?: Maybe<Scalars['String']>;
  Offres?: Maybe<Array<OffreService>>;
  OffresServices?: Maybe<Array<OffreService>>;
  Pays: Scalars['String'];
  Secteurs?: Maybe<Array<Secteur>>;
  Type?: Maybe<Scalars['String']>;
  adresse?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateNaissance?: Maybe<Scalars['DateTime']>;
  dispo?: Maybe<Array<Disponibilite>>;
  email?: Maybe<Scalars['String']>;
  expoPushToken?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  infirExperiences?: Maybe<Scalars['DateTime']>;
  infirFonction?: Maybe<Scalars['String']>;
  infirRib?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  messages?: Maybe<Array<MessageType>>;
  nbrDemandetraiter: Scalars['Float'];
  nbrReviews?: Maybe<Scalars['Float']>;
  nom?: Maybe<Scalars['String']>;
  notificationParMail: Scalars['Boolean'];
  notificationParSms: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  ville: Scalars['String'];
};

export type PrestataireWithUser = {
  __typename?: 'PrestataireWithUser';
  Balance?: Maybe<Scalars['Float']>;
  ContratChemin?: Maybe<Scalars['String']>;
  Dateinscription?: Maybe<Scalars['DateTime']>;
  Disponibilites?: Maybe<Scalars['String']>;
  EnsAdresse?: Maybe<Scalars['String']>;
  EnsICE?: Maybe<Scalars['String']>;
  FraisService: Scalars['Float'];
  InfirCin?: Maybe<Scalars['String']>;
  InfirCinChemin?: Maybe<Scalars['String']>;
  InfirDiplome?: Maybe<Scalars['String']>;
  InfirDiplomeChemin?: Maybe<Scalars['String']>;
  InfirPatente?: Maybe<Scalars['String']>;
  InfirPrenom?: Maybe<Scalars['String']>;
  InfirRib?: Maybe<Scalars['Float']>;
  InfirSexe?: Maybe<Scalars['String']>;
  Offres?: Maybe<Array<OffreService>>;
  Pays: Scalars['String'];
  Secteurs?: Maybe<Array<Secteur>>;
  Type?: Maybe<Scalars['String']>;
  adresse?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateNaissance?: Maybe<Scalars['DateTime']>;
  dispo?: Maybe<Array<Disponibilite>>;
  email?: Maybe<Scalars['String']>;
  expoPushToken?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  infirExperiences?: Maybe<Scalars['DateTime']>;
  infirFonction?: Maybe<Scalars['String']>;
  infirRib?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  nbrDemandetraiter: Scalars['Float'];
  nbrReviews?: Maybe<Scalars['Float']>;
  nom?: Maybe<Scalars['String']>;
  notificationParMail: Scalars['Boolean'];
  notificationParSms: Scalars['Boolean'];
  photo?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  userId: User;
  ville: Scalars['String'];
};

export type Prestation = {
  __typename?: 'Prestation';
  PrestataireId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  options?: Maybe<Array<DemandeOption>>;
  patientId: Scalars['String'];
  prix: Scalars['Float'];
  service: Scalars['String'];
};

export type PrestatireSearchInput = {
  experiencesPro?: InputMaybe<Scalars['Float']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
  nbrDemandetraiter?: InputMaybe<Scalars['Float']>;
  phone?: InputMaybe<Scalars['String']>;
  sectures?: InputMaybe<Array<Scalars['String']>>;
  servicesGroup?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  ville?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  applyDiscount?: Maybe<Coupons>;
  calculateOrderPrice: CalCulationRes;
  fraisService: FraisService;
  generatePatientDemandeInvoice: Scalars['String'];
  getAdminPatientById: PatientWithAddresses;
  getAdminPrestataireById: PrestataireWithPhone;
  getAllCouponsUser: Array<CouponsUser>;
  getAllDemands: Array<AdminDemands>;
  getAllDemandsByCoupons: Array<Demande>;
  getAllDemandsForAffiliate: Array<DemandeForAffiliate>;
  getAllDemandsForAffiliateStats: DemandesForAffiliateStats;
  getAllDisponibilites: Array<Disponibilite>;
  getAllGroupedServices: Array<OffresGroup>;
  getAllMessages: Array<MessageType>;
  getAllNotification: Array<Notification>;
  getAllOffre: Array<OffreService>;
  getAllPaients: Array<PatientWithPhone>;
  getAllPrestataire: Array<PrestataireWithPhone>;
  getAllSecteuresByVilleId: Array<Secteur>;
  getAllServiceAndOption: Array<ServiceAndOptions>;
  getAllServiceAndOptionByGroup: Array<ServiceAndOptions>;
  getAllUsers: Array<User>;
  getAllVilles: Array<Ville>;
  getAllservicesDesc: Array<ServiceAndOptions>;
  getCouponById: Coupons;
  getCouponsStaticsByAdminId: CouponUserStats;
  getCouponsStaticsForAdminId: Array<CouponWithAdmin>;
  getCurrentPatient: PatientWithUser;
  getCurrentPatientDemandes: Array<DemandeWithPrestataire>;
  getCurrentPatientStatus: PatientStatus;
  getCurrentPrestataire: Prestataire;
  getCurrentPrestataireDemandes: Array<DemandeWithPatient>;
  getCurrentPrestataireDemandesByStatus: Array<DemandeWithPatient>;
  getCurrentPrestataireDemandesInProgresse: Array<DemandeWithPatient>;
  getCurrentPrestataireDisponibilite: Disponibilite;
  getCurrentPrestataireOffers: Array<OffreService>;
  getCurrentUserLocations: Array<Localisation>;
  getCurrentUserNotification: Array<Notification>;
  getDemandById: GetAdminDemandById;
  getDemandeByIdWithPatient: DemandeWithPatient;
  getDemandeByIdWithPrestataire: DemandeWithPrestataire;
  getOffreById: OffreServiceWithPrestataire;
  getOffreByServiceNameAndprestataireId: OffreServiceWithPrestataire;
  getOffresByGroup: OffreServiceWithPrestataireAndPagination;
  getOffresByGroupForGroup: OffreServiceWithPrestataireAndPagination;
  getOffresByGroupForGroupTest: OffreServiceWithPrestataireAndPagination;
  getOffresByGroupForSectures: OffreServiceWithPrestataireAndPagination;
  getOffresByGroupForVilles: OffreServiceWithPrestataireAndPagination;
  getOffresByServiceName: OffreServiceWithPrestataireAndPagination;
  getPatientById: Patient;
  getPatientDemands: Array<DemandeWithPrestataireAndPatientAndofferSerice>;
  getPrestataireById: Prestataire;
  getPrestataireDisponibilitesById: Disponibilite;
  getPrestataireOffers: Array<OffreService>;
  getPrestataireReviewsById: Array<Review>;
  getPrestatireDemands: Array<DemandeWithPrestataireAndPatientAndofferSerice>;
  getPrestatireUnavailableTime: Array<Scalars['DateTime']>;
  getServiceAndOptionById: ServiceAndOptions;
  getSignedUrl: Scalars['String'];
  getStatics: StaticsType;
  getUserById: User;
  hello: Scalars['Boolean'];
  helloPrestataire: Scalars['Boolean'];
  helloServiceAndOption: Scalars['Boolean'];
  isPatientAuth: Scalars['Boolean'];
  suggestionOffresByOfferId: Array<OffreServiceWithPrestataire>;
};


export type QueryApplyDiscountArgs = {
  coupon_name: Scalars['String'];
};


export type QueryCalculateOrderPriceArgs = {
  appliedDiscount?: InputMaybe<Scalars['String']>;
  offreServiceId: Scalars['String'];
  totalSeance: Scalars['Float'];
};


export type QueryGeneratePatientDemandeInvoiceArgs = {
  demandeId: Scalars['String'];
};


export type QueryGetAdminPatientByIdArgs = {
  patientId: Scalars['String'];
};


export type QueryGetAdminPrestataireByIdArgs = {
  PrestataireId: Scalars['String'];
};


export type QueryGetAllDemandsArgs = {
  search: DemandeSearchInput;
};


export type QueryGetAllDemandsByCouponsArgs = {
  becareCoupons: Array<Scalars['String']>;
};


export type QueryGetAllDemandsForAffiliateArgs = {
  code?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['String']>;
};


export type QueryGetAllPaientsArgs = {
  search: PatientSearchInput;
};


export type QueryGetAllPrestataireArgs = {
  search?: InputMaybe<PrestatireSearchInput>;
};


export type QueryGetAllSecteuresByVilleIdArgs = {
  villeId: Scalars['String'];
};


export type QueryGetAllServiceAndOptionByGroupArgs = {
  group: Scalars['String'];
};


export type QueryGetAllservicesDescArgs = {
  group?: InputMaybe<Scalars['String']>;
};


export type QueryGetCouponByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetCouponsStaticsByAdminIdArgs = {
  code?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['String']>;
};


export type QueryGetCouponsStaticsForAdminIdArgs = {
  code?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['String']>;
};


export type QueryGetCurrentPrestataireDemandesByStatusArgs = {
  status: Scalars['String'];
};


export type QueryGetDemandByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetDemandeByIdWithPatientArgs = {
  id: Scalars['String'];
};


export type QueryGetDemandeByIdWithPrestataireArgs = {
  id: Scalars['String'];
};


export type QueryGetOffreByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetOffreByServiceNameAndprestataireIdArgs = {
  prestataireId: Scalars['String'];
  serviceName: Scalars['String'];
};


export type QueryGetOffresByGroupArgs = {
  group: Scalars['String'];
  page: Scalars['Float'];
  secteur?: InputMaybe<Scalars['String']>;
  serviceName?: InputMaybe<Scalars['String']>;
};


export type QueryGetOffresByGroupForGroupArgs = {
  group?: InputMaybe<Scalars['String']>;
  page: Scalars['Float'];
  secture?: InputMaybe<Scalars['String']>;
  serviceName?: InputMaybe<Scalars['String']>;
  villeId?: InputMaybe<Scalars['String']>;
};


export type QueryGetOffresByGroupForGroupTestArgs = {
  group?: InputMaybe<Scalars['String']>;
  page: Scalars['Float'];
  secture?: InputMaybe<Scalars['String']>;
  serviceName?: InputMaybe<Scalars['String']>;
  villeId?: InputMaybe<Scalars['String']>;
};


export type QueryGetOffresByGroupForSecturesArgs = {
  group?: InputMaybe<Scalars['String']>;
  page: Scalars['Float'];
  secteur?: InputMaybe<Scalars['String']>;
  serviceName?: InputMaybe<Scalars['String']>;
  ville?: InputMaybe<Scalars['String']>;
};


export type QueryGetOffresByGroupForVillesArgs = {
  group?: InputMaybe<Scalars['String']>;
  page: Scalars['Float'];
  serviceName?: InputMaybe<Scalars['String']>;
  ville?: InputMaybe<Scalars['String']>;
};


export type QueryGetOffresByServiceNameArgs = {
  page: Scalars['Float'];
  serviceName: Scalars['String'];
};


export type QueryGetPatientByIdArgs = {
  patientId: Scalars['String'];
};


export type QueryGetPatientDemandsArgs = {
  patientId: Scalars['String'];
};


export type QueryGetPrestataireByIdArgs = {
  PrestataireId: Scalars['String'];
};


export type QueryGetPrestataireDisponibilitesByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetPrestataireOffersArgs = {
  prestataireId: Scalars['String'];
};


export type QueryGetPrestataireReviewsByIdArgs = {
  prestataireId: Scalars['String'];
};


export type QueryGetPrestatireDemandsArgs = {
  prestataireId: Scalars['String'];
};


export type QueryGetPrestatireUnavailableTimeArgs = {
  prestataireId: Scalars['String'];
};


export type QueryGetServiceAndOptionByIdArgs = {
  serviceId: Scalars['String'];
};


export type QueryGetUserByIdArgs = {
  userId: Scalars['String'];
};


export type QuerySuggestionOffresByOfferIdArgs = {
  offerId: Scalars['String'];
};

export type Review = {
  __typename?: 'Review';
  createdAt: Scalars['DateTime'];
  demandeId: Scalars['String'];
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  patientId: PatientForReview;
  rating: Scalars['Float'];
};

export type Secteur = {
  __typename?: 'Secteur';
  id: Scalars['ID'];
  secteurAR: Scalars['String'];
  secteurFR: Scalars['String'];
  villeId: Scalars['String'];
};

export type SendNotificationInput = {
  content?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  phones?: InputMaybe<Array<PhoneInput>>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  usersList?: InputMaybe<Array<Scalars['String']>>;
  whatsapp_template?: InputMaybe<Scalars['String']>;
};

export type ServiceAndOptions = {
  __typename?: 'ServiceAndOptions';
  Nom: Scalars['String'];
  createdAt: Scalars['DateTime'];
  group: Scalars['String'];
  id: Scalars['ID'];
  options?: Maybe<Array<Scalars['String']>>;
  serviceDesc: Scalars['String'];
};

export type StaticsType = {
  __typename?: 'StaticsType';
  approved_prestataire?: Maybe<Scalars['Float']>;
  complated_prestation?: Maybe<Scalars['Float']>;
  total_Demands?: Maybe<Scalars['Float']>;
  total_amounts?: Maybe<Scalars['Float']>;
  total_becare_amounts?: Maybe<Scalars['Float']>;
  total_offres?: Maybe<Scalars['Float']>;
  total_patient?: Maybe<Scalars['Float']>;
  total_paye_amounts?: Maybe<Scalars['Float']>;
  total_prestataire?: Maybe<Scalars['Float']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  trackDemandeById: DemandeStatus;
  trackPrestataire: PrestataireLongLat;
};


export type SubscriptionTrackDemandeByIdArgs = {
  demandeId: Scalars['String'];
};


export type SubscriptionTrackPrestataireArgs = {
  prestataireId: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  status: Scalars['String'];
  telephone: Scalars['String'];
  tokenVersion: Scalars['Float'];
  type: Scalars['String'];
};

export type Ville = {
  __typename?: 'Ville';
  id: Scalars['ID'];
  ville: Scalars['String'];
};

export type AddManyDemandesResponse = {
  __typename?: 'addManyDemandesResponse';
  code?: Maybe<Scalars['String']>;
  is_success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['String']>;
  redirect_url?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['String']>;
};

export type AvailabilityTime = {
  __typename?: 'availabilityTime';
  EndTime: Scalars['String'];
  Jour: Scalars['String'];
  StartTime: Scalars['String'];
};

export type AvailabilityTimeArgs = {
  EndTime: Scalars['String'];
  Jour: Scalars['String'];
  StartTime: Scalars['String'];
};

export type FraisService = {
  __typename?: 'fraisService';
  PourcentageServicePatient: Scalars['Float'];
  PourcentageServicePrestataire: Scalars['Float'];
};

export type GetAdminDemandById = {
  __typename?: 'getAdminDemandById';
  AppliedDiscountAmount?: Maybe<Scalars['Float']>;
  AppliedDiscountPercentage?: Maybe<Scalars['Float']>;
  FraisServicePatient: Scalars['Float'];
  FraisServicePrestataire: Scalars['Float'];
  PourcentageServicePatient: Scalars['Float'];
  PourcentageServicePrestataire: Scalars['Float'];
  Review?: Maybe<Scalars['String']>;
  adressePatient: Scalars['String'];
  becareCoupon?: Maybe<Coupons>;
  calculatedAmount?: Maybe<Scalars['Float']>;
  cashPlusCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  dateSeance: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isPayed?: Maybe<Scalars['Boolean']>;
  latitudePatient?: Maybe<Scalars['Float']>;
  longitudePatient?: Maybe<Scalars['Float']>;
  modePaiment: Scalars['String'];
  montant: Scalars['Float'];
  nbrSeance: Scalars['Float'];
  offerSericeId?: Maybe<OffreService>;
  patientId: Patient;
  patientInvoiceNumber?: Maybe<Scalars['Float']>;
  patientInvoicePath?: Maybe<Scalars['String']>;
  prestataireId: Prestataire;
  prestation: Array<Prestation>;
  quantite: Scalars['String'];
  status: Scalars['String'];
  statusDemande: Array<DemandeStatus>;
};

export type MultioffreServiceArgs = {
  offres: Array<OffreServiceArgs>;
};

export type Notification = {
  __typename?: 'notification';
  contenu: Scalars['String'];
  date?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  type: Scalars['String'];
  userId: Scalars['String'];
};

export type NotificationInput = {
  contenu: Scalars['String'];
  date?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  type: Scalars['String'];
};

export type OffreService = {
  __typename?: 'offreService';
  Nom: Scalars['String'];
  createdAt: Scalars['DateTime'];
  discounts: Array<DiscountsOffreSrvice>;
  group: Scalars['String'];
  id: Scalars['ID'];
  isSelected?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Array<MyOption>>;
  prestataireId?: Maybe<Scalars['String']>;
  prixService: Scalars['Float'];
  serviceName: Scalars['String'];
};

export type OffreServiceArgs = {
  discounts: Array<DiscountsOffreSrviceInput>;
  group: Scalars['String'];
  options: Array<MyOptiona>;
  prixService: Scalars['Float'];
  serviceDesc?: InputMaybe<Scalars['String']>;
  serviceName: Scalars['String'];
};

export type OffreServiceWithPrestataire = {
  __typename?: 'offreServiceWithPrestataire';
  createdAt: Scalars['DateTime'];
  discounts: Array<DiscountsOffreSrvice>;
  dispo?: Maybe<Disponibilite>;
  group: Scalars['String'];
  id: Scalars['String'];
  options?: Maybe<Array<MyOption>>;
  prestataireId?: Maybe<PrestataireWithUser>;
  prixService: Scalars['Float'];
  serviceDesc?: Maybe<Scalars['String']>;
  serviceName: Scalars['String'];
};

export type OffreServiceWithPrestataireAndPagination = {
  __typename?: 'offreServiceWithPrestataireAndPagination';
  docs?: Maybe<Array<OffreServiceWithPrestataire>>;
  limit: Scalars['Float'];
  page: Scalars['Float'];
  pagingCounter: Scalars['Float'];
  secteur: Scalars['String'];
  totalDocs: Scalars['Float'];
  totalPages: Scalars['Float'];
};

export type PatientForReview = {
  __typename?: 'patientForReview';
  nom: Scalars['ID'];
  photo?: Maybe<Scalars['String']>;
  prenom: Scalars['String'];
};

export type ProgrammeS = {
  program: Array<AvailabilityTimeArgs>;
};

export type ReviewInput = {
  demandeId: Scalars['String'];
  message: Scalars['String'];
  rating: Scalars['Float'];
};

export type UpdateAccountInfo = {
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  last_name?: InputMaybe<Scalars['String']>;
};

export type UpdateAccountPassword = {
  confirm_password: Scalars['String'];
  old_password: Scalars['String'];
  password: Scalars['String'];
};

export type UpdatePrestatireInfo = {
  InfirCin?: InputMaybe<Scalars['String']>;
  InfirDiplome?: InputMaybe<Scalars['String']>;
  InfirPrenom?: InputMaybe<Scalars['String']>;
  InfirRib?: InputMaybe<Scalars['Float']>;
  InfirSexe?: InputMaybe<Scalars['String']>;
  Pays?: InputMaybe<Scalars['String']>;
  adresse?: InputMaybe<Scalars['String']>;
  dateNaissance?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  infirFonction?: InputMaybe<Scalars['String']>;
  nom?: InputMaybe<Scalars['String']>;
  ville?: InputMaybe<Scalars['String']>;
};

export type LoginAdminMutationVariables = Exact<{
  data: LoginAdminArgs;
}>;


export type LoginAdminMutation = { __typename?: 'Mutation', loginAdmin: boolean };

export type AddAccountMutationVariables = Exact<{
  data: AddAccountAdminArgs;
}>;


export type AddAccountMutation = { __typename?: 'Mutation', addAdminAccount: boolean };

export type SendNotificationMutationVariables = Exact<{
  data: SendNotificationInput;
}>;


export type SendNotificationMutation = { __typename?: 'Mutation', sendNotication: boolean };

export type UpdatePrestatireStatusMutationVariables = Exact<{
  id: Scalars['String'];
  status: Scalars['String'];
}>;


export type UpdatePrestatireStatusMutation = { __typename?: 'Mutation', updatePrestatireStatus: boolean };

export type AdminLogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type AdminLogoutMutation = { __typename?: 'Mutation', adminlogout: boolean };

export type AddStaffAccountMutationVariables = Exact<{
  data: AddAccountStaffArgs;
}>;


export type AddStaffAccountMutation = { __typename?: 'Mutation', addStaffAccount: boolean };

export type UpdateAdminInfoMutationVariables = Exact<{
  data: UpdateAccountInfo;
}>;


export type UpdateAdminInfoMutation = { __typename?: 'Mutation', updateAccountInfo: { __typename?: 'AccountInfoType', first_name?: string | null, last_name?: string | null, email?: string | null } };

export type UpdatePasswordMutationVariables = Exact<{
  data: UpdateAccountPassword;
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword: boolean };

export type GetStaticsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStaticsQuery = { __typename?: 'Query', getStatics: { __typename?: 'StaticsType', total_offres?: number | null, total_patient?: number | null, total_Demands?: number | null, total_prestataire?: number | null, total_amounts?: number | null, total_paye_amounts?: number | null, total_becare_amounts?: number | null, complated_prestation?: number | null, approved_prestataire?: number | null } };

export type GetAllCouponsUersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllCouponsUersQuery = { __typename?: 'Query', getAllCouponsUser: Array<{ __typename?: 'CouponsUser', label?: string | null, value?: string | null }> };

export type CreateCouponMutationVariables = Exact<{
  data: CraetecouponInputs;
}>;


export type CreateCouponMutation = { __typename?: 'Mutation', createCoupon: boolean };

export type GetCouponsStaticsByAdminIdQueryVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['String']>;
}>;


export type GetCouponsStaticsByAdminIdQuery = { __typename?: 'Query', getCouponsStaticsByAdminId: { __typename?: 'CouponUserStats', activeCoupons?: number | null, expriedCoupons?: number | null, totalUsedTimes?: number | null, coupons?: Array<{ __typename?: 'Coupons', coupon_name?: string | null, coupons_code?: string | null, percentAmount?: number | null, fixedAmount?: number | null, start_date?: string | null, end_date?: string | null, used_times?: number | null, id?: string | null, type?: string | null }> | null } };

export type GetCouponsStaticsForAdminIdQueryVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['String']>;
}>;


export type GetCouponsStaticsForAdminIdQuery = { __typename?: 'Query', getCouponsStaticsForAdminId: Array<{ __typename?: 'CouponWithAdmin', coupons_code?: string | null, adminId?: string | null, end_date?: string | null, start_date?: string | null, used_times?: number | null, coupon_name?: string | null, fixedAmount?: number | null, type?: string | null, percentAmount?: number | null, id?: string | null, admin?: { __typename?: 'Admin', first_name: string, last_name: string, role: string, email: string } | null }> };

export type UpdateCouponMutationVariables = Exact<{
  data: CraetecouponInputs;
  id: Scalars['String'];
}>;


export type UpdateCouponMutation = { __typename?: 'Mutation', updateCoupon: boolean };

export type GetCouponByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCouponByIdQuery = { __typename?: 'Query', getCouponById: { __typename?: 'Coupons', coupons_code?: string | null, start_date?: string | null, end_date?: string | null, coupon_name?: string | null, fixedAmount?: number | null, percentAmount?: number | null, type?: string | null, id?: string | null, used_times?: number | null } };

export type DeleteCouponMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteCouponMutation = { __typename?: 'Mutation', deleteCoupon: boolean };

export type GetAllDemandsQueryVariables = Exact<{
  search: DemandeSearchInput;
}>;


export type GetAllDemandsQuery = { __typename?: 'Query', getAllDemands: Array<{ __typename?: 'AdminDemands', dateSeance: any, id: string, montant: number, adressePatient: string, modePaiment: string, description: string, FraisServicePrestataire: number, status: string, longitudePatient?: number | null, latitudePatient?: number | null, createdAt: any, prestataireId: { __typename?: 'Prestataire', nom?: string | null, InfirPrenom?: string | null, email?: string | null, Pays: string, adresse?: string | null, ville: string, photo?: string | null, nbrDemandetraiter: number, Dateinscription?: any | null, infirRib?: number | null, InfirSexe?: string | null, InfirCin?: string | null, InfirDiplome?: string | null, status?: string | null, InfirDiplomeChemin?: string | null, InfirCinChemin?: string | null, infirExperiences?: any | null }, patientId: { __typename?: 'Patient', id: string, nom?: string | null, prenom?: string | null, pay?: string | null, email?: string | null, sexe?: string | null, photo?: string | null, adresse?: string | null, dateNaissance?: any | null, ville?: string | null, nbrDemandePasser: string, cin?: string | null, userId: string }, offerSericeId?: { __typename?: 'offreService', id: string, serviceName: string } | null }> };

export type GetAllDemandsByCouponsQueryVariables = Exact<{
  becareCoupons: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetAllDemandsByCouponsQuery = { __typename?: 'Query', getAllDemandsByCoupons: Array<{ __typename?: 'Demande', modePaiment: string, status: string, createdAt: any, dateSeance: any, becareCoupon?: { __typename?: 'Coupons', coupons_code?: string | null, coupon_name?: string | null } | null, offerSericeId?: { __typename?: 'offreService', id: string, serviceName: string } | null }> };

export type GetAllDemandsForAffiliateQueryVariables = Exact<{
  code?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  end_date?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['String']>;
}>;


export type GetAllDemandsForAffiliateQuery = { __typename?: 'Query', getAllDemandsForAffiliate: Array<{ __typename?: 'DemandeForAffiliate', affiliateAmount: number, modePaiment: string, status: string, createdAt: any, dateSeance: any, becareCoupon?: { __typename?: 'Coupons', coupons_code?: string | null, coupon_name?: string | null } | null, offerSericeId?: { __typename?: 'offreService', serviceName: string } | null }> };

export type GetDemandeByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetDemandeByIdQuery = { __typename?: 'Query', getDemandById: { __typename?: 'getAdminDemandById', dateSeance: any, id: string, montant: number, adressePatient: string, modePaiment: string, description: string, FraisServicePrestataire: number, status: string, longitudePatient?: number | null, latitudePatient?: number | null, prestataireId: { __typename?: 'Prestataire', id: string, nom?: string | null, InfirPrenom?: string | null, email?: string | null, Pays: string, adresse?: string | null, ville: string, photo?: string | null, nbrDemandetraiter: number, Dateinscription?: any | null, infirRib?: number | null, InfirSexe?: string | null, InfirCin?: string | null, InfirDiplome?: string | null, status?: string | null, InfirDiplomeChemin?: string | null, InfirCinChemin?: string | null, infirExperiences?: any | null }, patientId: { __typename?: 'Patient', id: string, nom?: string | null, prenom?: string | null, pay?: string | null, email?: string | null, sexe?: string | null, photo?: string | null, adresse?: string | null, dateNaissance?: any | null, ville?: string | null, nbrDemandePasser: string, cin?: string | null, userId: string }, offerSericeId?: { __typename?: 'offreService', id: string, serviceName: string } | null } };

export type GetAllDemandsForAffiliateStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDemandsForAffiliateStatsQuery = { __typename?: 'Query', getAllDemandsForAffiliateStats: { __typename?: 'DemandesForAffiliateStats', totalAffiliateAmount: number, totalAffiliateAmountAccepted: number } };

export type AllMessagesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllMessagesQuery = { __typename?: 'Query', getAllMessages: Array<{ __typename?: 'MessageType', message: string, _id: string, senderPhoneNumber: string, createdAt: any, sender?: string | null, isRegistred?: boolean | null, isPrestataire: boolean, accountId?: string | null }> };

export type GetAllPaientsQueryVariables = Exact<{
  search: PatientSearchInput;
}>;


export type GetAllPaientsQuery = { __typename?: 'Query', getAllPaients: Array<{ __typename?: 'PatientWithPhone', id: string, nom?: string | null, prenom?: string | null, nbrDemandePasser: string, photo?: string | null, dateNaissance?: any | null, ville?: string | null, pay?: string | null, cin?: string | null, sexe?: string | null, email?: string | null, phone: string, createdAt: any }> };

export type GetAdminPaientByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAdminPaientByIdQuery = { __typename?: 'Query', getAdminPatientById: { __typename?: 'PatientWithAddresses', id: string, nom?: string | null, prenom?: string | null, nbrDemandePasser: string, photo?: string | null, dateNaissance?: any | null, ville?: string | null, pay?: string | null, cin?: string | null, sexe?: string | null, email?: string | null, phone: string, address: Array<{ __typename?: 'Localisation', adresse: string, latitude?: number | null, longitude?: number | null }> } };

export type GetPatientDemandsQueryVariables = Exact<{
  patientId: Scalars['String'];
}>;


export type GetPatientDemandsQuery = { __typename?: 'Query', getPatientDemands: Array<{ __typename?: 'DemandeWithPrestataireAndPatientAndofferSerice', id: string, status: string, montant: number, dateSeance: any, adressePatient: string, description: string, modePaiment: string, calculatedAmount?: number | null, AppliedDiscountAmount?: number | null, AppliedDiscountPercentage?: number | null, createdAt: any, cashPlusCode?: string | null, isPayed?: boolean | null, patientId: { __typename?: 'PatientWithUser', id: string, nom?: string | null, prenom?: string | null }, prestataireId: { __typename?: 'PrestataireWithUser', id: string, InfirPrenom?: string | null, nom?: string | null }, offerSerice: { __typename?: 'offreService', id: string, group: string, serviceName: string } }> };

export type GetAllPrestataireQueryVariables = Exact<{
  search?: InputMaybe<PrestatireSearchInput>;
}>;


export type GetAllPrestataireQuery = { __typename?: 'Query', getAllPrestataire: Array<{ __typename?: 'PrestataireWithPhone', id: string, phone?: string | null, nom?: string | null, email?: string | null, Pays: string, adresse?: string | null, ville: string, photo?: string | null, nbrDemandetraiter: number, Dateinscription?: any | null, Balance?: number | null, InfirPrenom?: string | null, InfirDiplome?: string | null, InfirSexe?: string | null, InfirCinChemin?: string | null, infirRib?: number | null, InfirDiplomeChemin?: string | null, dateNaissance?: any | null, createdAt?: any | null, infirFonction?: string | null, InfirCin?: string | null, status?: string | null, nbrReviews?: number | null, infirExperiences?: any | null, Offres?: Array<{ __typename?: 'offreService', group: string, prixService: number, serviceName: string }> | null, Secteurs?: Array<{ __typename?: 'Secteur', id: string, secteurFR: string }> | null, messages?: Array<{ __typename?: 'MessageType', message: string, vue?: boolean | null, sender?: string | null, senderPhoneNumber: string, createdAt: any }> | null }> };

export type GetAdminPrestataireByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetAdminPrestataireByIdQuery = { __typename?: 'Query', getAdminPrestataireById: { __typename?: 'PrestataireWithPhone', id: string, phone?: string | null, nom?: string | null, email?: string | null, Pays: string, adresse?: string | null, ville: string, photo?: string | null, nbrDemandetraiter: number, Dateinscription?: any | null, Balance?: number | null, InfirPrenom?: string | null, InfirDiplome?: string | null, InfirSexe?: string | null, InfirCinChemin?: string | null, InfirRib?: number | null, InfirDiplomeChemin?: string | null, dateNaissance?: any | null, infirFonction?: string | null, InfirCin?: string | null, status?: string | null, nbrReviews?: number | null, OffresServices?: Array<{ __typename?: 'offreService', prestataireId?: string | null, group: string, serviceName: string, prixService: number, discounts: Array<{ __typename?: 'DiscountsOffreSrvice', discount: number, nmbr: number }> }> | null, dispo?: Array<{ __typename?: 'Disponibilite', program: Array<{ __typename?: 'availabilityTime', Jour: string, EndTime: string, StartTime: string }> }> | null, Secteurs?: Array<{ __typename?: 'Secteur', secteurFR: string }> | null } };

export type UpdatePrestatireInfoMutationVariables = Exact<{
  data: UpdatePrestatireInfo;
  id: Scalars['String'];
}>;


export type UpdatePrestatireInfoMutation = { __typename?: 'Mutation', updatePrestatireInfoAdmin: boolean };

export type GetPrestatireDemandsQueryVariables = Exact<{
  prestatiareId: Scalars['String'];
}>;


export type GetPrestatireDemandsQuery = { __typename?: 'Query', getPrestatireDemands: Array<{ __typename?: 'DemandeWithPrestataireAndPatientAndofferSerice', id: string, status: string, montant: number, dateSeance: any, adressePatient: string, description: string, modePaiment: string, calculatedAmount?: number | null, AppliedDiscountAmount?: number | null, AppliedDiscountPercentage?: number | null, createdAt: any, cashPlusCode?: string | null, isPayed?: boolean | null, patientId: { __typename?: 'PatientWithUser', id: string, nom?: string | null, prenom?: string | null }, prestataireId: { __typename?: 'PrestataireWithUser', id: string, InfirPrenom?: string | null, nom?: string | null }, offerSerice: { __typename?: 'offreService', id: string, group: string, serviceName: string } }> };

export type GelAlLsecturesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GelAlLsecturesQuery = { __typename?: 'Query', getAllSecteuresByVilleId: Array<{ __typename?: 'Secteur', id: string, secteurFR: string }> };

export type GetAllServiceAndOptionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllServiceAndOptionQuery = { __typename?: 'Query', getAllServiceAndOption: Array<{ __typename?: 'ServiceAndOptions', group: string }> };


export const LoginAdminDocument = gql`
    mutation LoginAdmin($data: LoginAdminArgs!) {
  loginAdmin(data: $data)
}
    `;
export type LoginAdminMutationFn = Apollo.MutationFunction<LoginAdminMutation, LoginAdminMutationVariables>;

/**
 * __useLoginAdminMutation__
 *
 * To run a mutation, you first call `useLoginAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAdminMutation, { data, loading, error }] = useLoginAdminMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useLoginAdminMutation(baseOptions?: Apollo.MutationHookOptions<LoginAdminMutation, LoginAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginAdminMutation, LoginAdminMutationVariables>(LoginAdminDocument, options);
      }
export type LoginAdminMutationHookResult = ReturnType<typeof useLoginAdminMutation>;
export type LoginAdminMutationResult = Apollo.MutationResult<LoginAdminMutation>;
export type LoginAdminMutationOptions = Apollo.BaseMutationOptions<LoginAdminMutation, LoginAdminMutationVariables>;
export const AddAccountDocument = gql`
    mutation AddAccount($data: AddAccountAdminArgs!) {
  addAdminAccount(data: $data)
}
    `;
export type AddAccountMutationFn = Apollo.MutationFunction<AddAccountMutation, AddAccountMutationVariables>;

/**
 * __useAddAccountMutation__
 *
 * To run a mutation, you first call `useAddAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAccountMutation, { data, loading, error }] = useAddAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddAccountMutation(baseOptions?: Apollo.MutationHookOptions<AddAccountMutation, AddAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddAccountMutation, AddAccountMutationVariables>(AddAccountDocument, options);
      }
export type AddAccountMutationHookResult = ReturnType<typeof useAddAccountMutation>;
export type AddAccountMutationResult = Apollo.MutationResult<AddAccountMutation>;
export type AddAccountMutationOptions = Apollo.BaseMutationOptions<AddAccountMutation, AddAccountMutationVariables>;
export const SendNotificationDocument = gql`
    mutation sendNotification($data: SendNotificationInput!) {
  sendNotication(data: $data)
}
    `;
export type SendNotificationMutationFn = Apollo.MutationFunction<SendNotificationMutation, SendNotificationMutationVariables>;

/**
 * __useSendNotificationMutation__
 *
 * To run a mutation, you first call `useSendNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNotificationMutation, { data, loading, error }] = useSendNotificationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendNotificationMutation, SendNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendNotificationMutation, SendNotificationMutationVariables>(SendNotificationDocument, options);
      }
export type SendNotificationMutationHookResult = ReturnType<typeof useSendNotificationMutation>;
export type SendNotificationMutationResult = Apollo.MutationResult<SendNotificationMutation>;
export type SendNotificationMutationOptions = Apollo.BaseMutationOptions<SendNotificationMutation, SendNotificationMutationVariables>;
export const UpdatePrestatireStatusDocument = gql`
    mutation updatePrestatireStatus($id: String!, $status: String!) {
  updatePrestatireStatus(status: $status, prestataireId: $id)
}
    `;
export type UpdatePrestatireStatusMutationFn = Apollo.MutationFunction<UpdatePrestatireStatusMutation, UpdatePrestatireStatusMutationVariables>;

/**
 * __useUpdatePrestatireStatusMutation__
 *
 * To run a mutation, you first call `useUpdatePrestatireStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrestatireStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrestatireStatusMutation, { data, loading, error }] = useUpdatePrestatireStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdatePrestatireStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrestatireStatusMutation, UpdatePrestatireStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrestatireStatusMutation, UpdatePrestatireStatusMutationVariables>(UpdatePrestatireStatusDocument, options);
      }
export type UpdatePrestatireStatusMutationHookResult = ReturnType<typeof useUpdatePrestatireStatusMutation>;
export type UpdatePrestatireStatusMutationResult = Apollo.MutationResult<UpdatePrestatireStatusMutation>;
export type UpdatePrestatireStatusMutationOptions = Apollo.BaseMutationOptions<UpdatePrestatireStatusMutation, UpdatePrestatireStatusMutationVariables>;
export const AdminLogoutDocument = gql`
    mutation adminLogout {
  adminlogout
}
    `;
export type AdminLogoutMutationFn = Apollo.MutationFunction<AdminLogoutMutation, AdminLogoutMutationVariables>;

/**
 * __useAdminLogoutMutation__
 *
 * To run a mutation, you first call `useAdminLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLogoutMutation, { data, loading, error }] = useAdminLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useAdminLogoutMutation(baseOptions?: Apollo.MutationHookOptions<AdminLogoutMutation, AdminLogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminLogoutMutation, AdminLogoutMutationVariables>(AdminLogoutDocument, options);
      }
export type AdminLogoutMutationHookResult = ReturnType<typeof useAdminLogoutMutation>;
export type AdminLogoutMutationResult = Apollo.MutationResult<AdminLogoutMutation>;
export type AdminLogoutMutationOptions = Apollo.BaseMutationOptions<AdminLogoutMutation, AdminLogoutMutationVariables>;
export const AddStaffAccountDocument = gql`
    mutation AddStaffAccount($data: AddAccountStaffArgs!) {
  addStaffAccount(data: $data)
}
    `;
export type AddStaffAccountMutationFn = Apollo.MutationFunction<AddStaffAccountMutation, AddStaffAccountMutationVariables>;

/**
 * __useAddStaffAccountMutation__
 *
 * To run a mutation, you first call `useAddStaffAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStaffAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStaffAccountMutation, { data, loading, error }] = useAddStaffAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAddStaffAccountMutation(baseOptions?: Apollo.MutationHookOptions<AddStaffAccountMutation, AddStaffAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStaffAccountMutation, AddStaffAccountMutationVariables>(AddStaffAccountDocument, options);
      }
export type AddStaffAccountMutationHookResult = ReturnType<typeof useAddStaffAccountMutation>;
export type AddStaffAccountMutationResult = Apollo.MutationResult<AddStaffAccountMutation>;
export type AddStaffAccountMutationOptions = Apollo.BaseMutationOptions<AddStaffAccountMutation, AddStaffAccountMutationVariables>;
export const UpdateAdminInfoDocument = gql`
    mutation updateAdminInfo($data: updateAccountInfo!) {
  updateAccountInfo(data: $data) {
    first_name
    last_name
    email
  }
}
    `;
export type UpdateAdminInfoMutationFn = Apollo.MutationFunction<UpdateAdminInfoMutation, UpdateAdminInfoMutationVariables>;

/**
 * __useUpdateAdminInfoMutation__
 *
 * To run a mutation, you first call `useUpdateAdminInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminInfoMutation, { data, loading, error }] = useUpdateAdminInfoMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAdminInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdminInfoMutation, UpdateAdminInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdminInfoMutation, UpdateAdminInfoMutationVariables>(UpdateAdminInfoDocument, options);
      }
export type UpdateAdminInfoMutationHookResult = ReturnType<typeof useUpdateAdminInfoMutation>;
export type UpdateAdminInfoMutationResult = Apollo.MutationResult<UpdateAdminInfoMutation>;
export type UpdateAdminInfoMutationOptions = Apollo.BaseMutationOptions<UpdateAdminInfoMutation, UpdateAdminInfoMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation updatePassword($data: updateAccountPassword!) {
  updatePassword(data: $data)
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const GetStaticsDocument = gql`
    query getStatics {
  getStatics {
    total_offres
    total_patient
    total_Demands
    total_prestataire
    total_prestataire
    total_amounts
    total_paye_amounts
    total_becare_amounts
    complated_prestation
    approved_prestataire
  }
}
    `;

/**
 * __useGetStaticsQuery__
 *
 * To run a query within a React component, call `useGetStaticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStaticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStaticsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStaticsQuery(baseOptions?: Apollo.QueryHookOptions<GetStaticsQuery, GetStaticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStaticsQuery, GetStaticsQueryVariables>(GetStaticsDocument, options);
      }
export function useGetStaticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStaticsQuery, GetStaticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStaticsQuery, GetStaticsQueryVariables>(GetStaticsDocument, options);
        }
export type GetStaticsQueryHookResult = ReturnType<typeof useGetStaticsQuery>;
export type GetStaticsLazyQueryHookResult = ReturnType<typeof useGetStaticsLazyQuery>;
export type GetStaticsQueryResult = Apollo.QueryResult<GetStaticsQuery, GetStaticsQueryVariables>;
export const GetAllCouponsUersDocument = gql`
    query getAllCouponsUers {
  getAllCouponsUser {
    label
    value
  }
}
    `;

/**
 * __useGetAllCouponsUersQuery__
 *
 * To run a query within a React component, call `useGetAllCouponsUersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCouponsUersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCouponsUersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCouponsUersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCouponsUersQuery, GetAllCouponsUersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCouponsUersQuery, GetAllCouponsUersQueryVariables>(GetAllCouponsUersDocument, options);
      }
export function useGetAllCouponsUersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCouponsUersQuery, GetAllCouponsUersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCouponsUersQuery, GetAllCouponsUersQueryVariables>(GetAllCouponsUersDocument, options);
        }
export type GetAllCouponsUersQueryHookResult = ReturnType<typeof useGetAllCouponsUersQuery>;
export type GetAllCouponsUersLazyQueryHookResult = ReturnType<typeof useGetAllCouponsUersLazyQuery>;
export type GetAllCouponsUersQueryResult = Apollo.QueryResult<GetAllCouponsUersQuery, GetAllCouponsUersQueryVariables>;
export const CreateCouponDocument = gql`
    mutation createCoupon($data: CraetecouponInputs!) {
  createCoupon(data: $data)
}
    `;
export type CreateCouponMutationFn = Apollo.MutationFunction<CreateCouponMutation, CreateCouponMutationVariables>;

/**
 * __useCreateCouponMutation__
 *
 * To run a mutation, you first call `useCreateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCouponMutation, { data, loading, error }] = useCreateCouponMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCouponMutation(baseOptions?: Apollo.MutationHookOptions<CreateCouponMutation, CreateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCouponMutation, CreateCouponMutationVariables>(CreateCouponDocument, options);
      }
export type CreateCouponMutationHookResult = ReturnType<typeof useCreateCouponMutation>;
export type CreateCouponMutationResult = Apollo.MutationResult<CreateCouponMutation>;
export type CreateCouponMutationOptions = Apollo.BaseMutationOptions<CreateCouponMutation, CreateCouponMutationVariables>;
export const GetCouponsStaticsByAdminIdDocument = gql`
    query getCouponsStaticsByAdminId($code: String, $name: String, $end_date: String, $start_date: String) {
  getCouponsStaticsByAdminId(
    end_date: $end_date
    start_date: $start_date
    name: $name
    code: $code
  ) {
    activeCoupons
    expriedCoupons
    totalUsedTimes
    coupons {
      coupon_name
      coupons_code
      percentAmount
      fixedAmount
      start_date
      end_date
      used_times
      id
      type
    }
  }
}
    `;

/**
 * __useGetCouponsStaticsByAdminIdQuery__
 *
 * To run a query within a React component, call `useGetCouponsStaticsByAdminIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponsStaticsByAdminIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponsStaticsByAdminIdQuery({
 *   variables: {
 *      code: // value for 'code'
 *      name: // value for 'name'
 *      end_date: // value for 'end_date'
 *      start_date: // value for 'start_date'
 *   },
 * });
 */
export function useGetCouponsStaticsByAdminIdQuery(baseOptions?: Apollo.QueryHookOptions<GetCouponsStaticsByAdminIdQuery, GetCouponsStaticsByAdminIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCouponsStaticsByAdminIdQuery, GetCouponsStaticsByAdminIdQueryVariables>(GetCouponsStaticsByAdminIdDocument, options);
      }
export function useGetCouponsStaticsByAdminIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponsStaticsByAdminIdQuery, GetCouponsStaticsByAdminIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCouponsStaticsByAdminIdQuery, GetCouponsStaticsByAdminIdQueryVariables>(GetCouponsStaticsByAdminIdDocument, options);
        }
export type GetCouponsStaticsByAdminIdQueryHookResult = ReturnType<typeof useGetCouponsStaticsByAdminIdQuery>;
export type GetCouponsStaticsByAdminIdLazyQueryHookResult = ReturnType<typeof useGetCouponsStaticsByAdminIdLazyQuery>;
export type GetCouponsStaticsByAdminIdQueryResult = Apollo.QueryResult<GetCouponsStaticsByAdminIdQuery, GetCouponsStaticsByAdminIdQueryVariables>;
export const GetCouponsStaticsForAdminIdDocument = gql`
    query getCouponsStaticsForAdminId($code: String, $name: String, $end_date: String, $start_date: String) {
  getCouponsStaticsForAdminId(
    end_date: $end_date
    start_date: $start_date
    name: $name
    code: $code
  ) {
    coupons_code
    adminId
    end_date
    start_date
    used_times
    coupon_name
    fixedAmount
    type
    percentAmount
    id
    admin {
      first_name
      last_name
      role
      email
    }
  }
}
    `;

/**
 * __useGetCouponsStaticsForAdminIdQuery__
 *
 * To run a query within a React component, call `useGetCouponsStaticsForAdminIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponsStaticsForAdminIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponsStaticsForAdminIdQuery({
 *   variables: {
 *      code: // value for 'code'
 *      name: // value for 'name'
 *      end_date: // value for 'end_date'
 *      start_date: // value for 'start_date'
 *   },
 * });
 */
export function useGetCouponsStaticsForAdminIdQuery(baseOptions?: Apollo.QueryHookOptions<GetCouponsStaticsForAdminIdQuery, GetCouponsStaticsForAdminIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCouponsStaticsForAdminIdQuery, GetCouponsStaticsForAdminIdQueryVariables>(GetCouponsStaticsForAdminIdDocument, options);
      }
export function useGetCouponsStaticsForAdminIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponsStaticsForAdminIdQuery, GetCouponsStaticsForAdminIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCouponsStaticsForAdminIdQuery, GetCouponsStaticsForAdminIdQueryVariables>(GetCouponsStaticsForAdminIdDocument, options);
        }
export type GetCouponsStaticsForAdminIdQueryHookResult = ReturnType<typeof useGetCouponsStaticsForAdminIdQuery>;
export type GetCouponsStaticsForAdminIdLazyQueryHookResult = ReturnType<typeof useGetCouponsStaticsForAdminIdLazyQuery>;
export type GetCouponsStaticsForAdminIdQueryResult = Apollo.QueryResult<GetCouponsStaticsForAdminIdQuery, GetCouponsStaticsForAdminIdQueryVariables>;
export const UpdateCouponDocument = gql`
    mutation updateCoupon($data: CraetecouponInputs!, $id: String!) {
  updateCoupon(data: $data, id: $id)
}
    `;
export type UpdateCouponMutationFn = Apollo.MutationFunction<UpdateCouponMutation, UpdateCouponMutationVariables>;

/**
 * __useUpdateCouponMutation__
 *
 * To run a mutation, you first call `useUpdateCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCouponMutation, { data, loading, error }] = useUpdateCouponMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCouponMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCouponMutation, UpdateCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCouponMutation, UpdateCouponMutationVariables>(UpdateCouponDocument, options);
      }
export type UpdateCouponMutationHookResult = ReturnType<typeof useUpdateCouponMutation>;
export type UpdateCouponMutationResult = Apollo.MutationResult<UpdateCouponMutation>;
export type UpdateCouponMutationOptions = Apollo.BaseMutationOptions<UpdateCouponMutation, UpdateCouponMutationVariables>;
export const GetCouponByIdDocument = gql`
    query getCouponById($id: String!) {
  getCouponById(id: $id) {
    coupons_code
    start_date
    end_date
    coupon_name
    fixedAmount
    percentAmount
    type
    id
    used_times
  }
}
    `;

/**
 * __useGetCouponByIdQuery__
 *
 * To run a query within a React component, call `useGetCouponByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCouponByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCouponByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCouponByIdQuery(baseOptions: Apollo.QueryHookOptions<GetCouponByIdQuery, GetCouponByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCouponByIdQuery, GetCouponByIdQueryVariables>(GetCouponByIdDocument, options);
      }
export function useGetCouponByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCouponByIdQuery, GetCouponByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCouponByIdQuery, GetCouponByIdQueryVariables>(GetCouponByIdDocument, options);
        }
export type GetCouponByIdQueryHookResult = ReturnType<typeof useGetCouponByIdQuery>;
export type GetCouponByIdLazyQueryHookResult = ReturnType<typeof useGetCouponByIdLazyQuery>;
export type GetCouponByIdQueryResult = Apollo.QueryResult<GetCouponByIdQuery, GetCouponByIdQueryVariables>;
export const DeleteCouponDocument = gql`
    mutation deleteCoupon($id: String!) {
  deleteCoupon(id: $id)
}
    `;
export type DeleteCouponMutationFn = Apollo.MutationFunction<DeleteCouponMutation, DeleteCouponMutationVariables>;

/**
 * __useDeleteCouponMutation__
 *
 * To run a mutation, you first call `useDeleteCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCouponMutation, { data, loading, error }] = useDeleteCouponMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCouponMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCouponMutation, DeleteCouponMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCouponMutation, DeleteCouponMutationVariables>(DeleteCouponDocument, options);
      }
export type DeleteCouponMutationHookResult = ReturnType<typeof useDeleteCouponMutation>;
export type DeleteCouponMutationResult = Apollo.MutationResult<DeleteCouponMutation>;
export type DeleteCouponMutationOptions = Apollo.BaseMutationOptions<DeleteCouponMutation, DeleteCouponMutationVariables>;
export const GetAllDemandsDocument = gql`
    query GetAllDemands($search: DemandeSearchInput!) {
  getAllDemands(search: $search) {
    dateSeance
    prestataireId {
      nom
      InfirPrenom
      email
      Pays
      adresse
      ville
      photo
      nbrDemandetraiter
      Dateinscription
      infirRib
      InfirSexe
      InfirCin
      InfirDiplome
      status
      InfirDiplomeChemin
      InfirCinChemin
      infirExperiences
    }
    patientId {
      id
      nom
      prenom
      pay
      email
      sexe
      photo
      adresse
      dateNaissance
      ville
      nbrDemandePasser
      cin
      userId
    }
    id
    montant
    adressePatient
    modePaiment
    description
    offerSericeId {
      id
      serviceName
    }
    FraisServicePrestataire
    status
    longitudePatient
    latitudePatient
    createdAt
  }
}
    `;

/**
 * __useGetAllDemandsQuery__
 *
 * To run a query within a React component, call `useGetAllDemandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDemandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDemandsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetAllDemandsQuery(baseOptions: Apollo.QueryHookOptions<GetAllDemandsQuery, GetAllDemandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDemandsQuery, GetAllDemandsQueryVariables>(GetAllDemandsDocument, options);
      }
export function useGetAllDemandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDemandsQuery, GetAllDemandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDemandsQuery, GetAllDemandsQueryVariables>(GetAllDemandsDocument, options);
        }
export type GetAllDemandsQueryHookResult = ReturnType<typeof useGetAllDemandsQuery>;
export type GetAllDemandsLazyQueryHookResult = ReturnType<typeof useGetAllDemandsLazyQuery>;
export type GetAllDemandsQueryResult = Apollo.QueryResult<GetAllDemandsQuery, GetAllDemandsQueryVariables>;
export const GetAllDemandsByCouponsDocument = gql`
    query getAllDemandsByCoupons($becareCoupons: [String!]!) {
  getAllDemandsByCoupons(becareCoupons: $becareCoupons) {
    modePaiment
    status
    createdAt
    dateSeance
    becareCoupon {
      coupons_code
      coupon_name
    }
    offerSericeId {
      id
      serviceName
    }
  }
}
    `;

/**
 * __useGetAllDemandsByCouponsQuery__
 *
 * To run a query within a React component, call `useGetAllDemandsByCouponsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDemandsByCouponsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDemandsByCouponsQuery({
 *   variables: {
 *      becareCoupons: // value for 'becareCoupons'
 *   },
 * });
 */
export function useGetAllDemandsByCouponsQuery(baseOptions: Apollo.QueryHookOptions<GetAllDemandsByCouponsQuery, GetAllDemandsByCouponsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDemandsByCouponsQuery, GetAllDemandsByCouponsQueryVariables>(GetAllDemandsByCouponsDocument, options);
      }
export function useGetAllDemandsByCouponsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDemandsByCouponsQuery, GetAllDemandsByCouponsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDemandsByCouponsQuery, GetAllDemandsByCouponsQueryVariables>(GetAllDemandsByCouponsDocument, options);
        }
export type GetAllDemandsByCouponsQueryHookResult = ReturnType<typeof useGetAllDemandsByCouponsQuery>;
export type GetAllDemandsByCouponsLazyQueryHookResult = ReturnType<typeof useGetAllDemandsByCouponsLazyQuery>;
export type GetAllDemandsByCouponsQueryResult = Apollo.QueryResult<GetAllDemandsByCouponsQuery, GetAllDemandsByCouponsQueryVariables>;
export const GetAllDemandsForAffiliateDocument = gql`
    query getAllDemandsForAffiliate($code: String, $name: String, $end_date: String, $start_date: String) {
  getAllDemandsForAffiliate(
    end_date: $end_date
    start_date: $start_date
    name: $name
    code: $code
  ) {
    affiliateAmount
    modePaiment
    status
    createdAt
    dateSeance
    becareCoupon {
      coupons_code
      coupon_name
    }
    offerSericeId {
      serviceName
    }
  }
}
    `;

/**
 * __useGetAllDemandsForAffiliateQuery__
 *
 * To run a query within a React component, call `useGetAllDemandsForAffiliateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDemandsForAffiliateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDemandsForAffiliateQuery({
 *   variables: {
 *      code: // value for 'code'
 *      name: // value for 'name'
 *      end_date: // value for 'end_date'
 *      start_date: // value for 'start_date'
 *   },
 * });
 */
export function useGetAllDemandsForAffiliateQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDemandsForAffiliateQuery, GetAllDemandsForAffiliateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDemandsForAffiliateQuery, GetAllDemandsForAffiliateQueryVariables>(GetAllDemandsForAffiliateDocument, options);
      }
export function useGetAllDemandsForAffiliateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDemandsForAffiliateQuery, GetAllDemandsForAffiliateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDemandsForAffiliateQuery, GetAllDemandsForAffiliateQueryVariables>(GetAllDemandsForAffiliateDocument, options);
        }
export type GetAllDemandsForAffiliateQueryHookResult = ReturnType<typeof useGetAllDemandsForAffiliateQuery>;
export type GetAllDemandsForAffiliateLazyQueryHookResult = ReturnType<typeof useGetAllDemandsForAffiliateLazyQuery>;
export type GetAllDemandsForAffiliateQueryResult = Apollo.QueryResult<GetAllDemandsForAffiliateQuery, GetAllDemandsForAffiliateQueryVariables>;
export const GetDemandeByIdDocument = gql`
    query getDemandeById($id: String!) {
  getDemandById(id: $id) {
    dateSeance
    prestataireId {
      id
      nom
      InfirPrenom
      email
      Pays
      adresse
      ville
      photo
      nbrDemandetraiter
      Dateinscription
      infirRib
      InfirSexe
      InfirCin
      InfirDiplome
      status
      InfirDiplomeChemin
      InfirCinChemin
      infirExperiences
    }
    patientId {
      id
      nom
      prenom
      pay
      email
      sexe
      photo
      adresse
      dateNaissance
      ville
      nbrDemandePasser
      cin
      userId
    }
    id
    montant
    adressePatient
    modePaiment
    description
    offerSericeId {
      id
      serviceName
    }
    FraisServicePrestataire
    status
    longitudePatient
    latitudePatient
  }
}
    `;

/**
 * __useGetDemandeByIdQuery__
 *
 * To run a query within a React component, call `useGetDemandeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDemandeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDemandeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDemandeByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDemandeByIdQuery, GetDemandeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDemandeByIdQuery, GetDemandeByIdQueryVariables>(GetDemandeByIdDocument, options);
      }
export function useGetDemandeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDemandeByIdQuery, GetDemandeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDemandeByIdQuery, GetDemandeByIdQueryVariables>(GetDemandeByIdDocument, options);
        }
export type GetDemandeByIdQueryHookResult = ReturnType<typeof useGetDemandeByIdQuery>;
export type GetDemandeByIdLazyQueryHookResult = ReturnType<typeof useGetDemandeByIdLazyQuery>;
export type GetDemandeByIdQueryResult = Apollo.QueryResult<GetDemandeByIdQuery, GetDemandeByIdQueryVariables>;
export const GetAllDemandsForAffiliateStatsDocument = gql`
    query getAllDemandsForAffiliateStats {
  getAllDemandsForAffiliateStats {
    totalAffiliateAmount
    totalAffiliateAmountAccepted
  }
}
    `;

/**
 * __useGetAllDemandsForAffiliateStatsQuery__
 *
 * To run a query within a React component, call `useGetAllDemandsForAffiliateStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllDemandsForAffiliateStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllDemandsForAffiliateStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllDemandsForAffiliateStatsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllDemandsForAffiliateStatsQuery, GetAllDemandsForAffiliateStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllDemandsForAffiliateStatsQuery, GetAllDemandsForAffiliateStatsQueryVariables>(GetAllDemandsForAffiliateStatsDocument, options);
      }
export function useGetAllDemandsForAffiliateStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllDemandsForAffiliateStatsQuery, GetAllDemandsForAffiliateStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllDemandsForAffiliateStatsQuery, GetAllDemandsForAffiliateStatsQueryVariables>(GetAllDemandsForAffiliateStatsDocument, options);
        }
export type GetAllDemandsForAffiliateStatsQueryHookResult = ReturnType<typeof useGetAllDemandsForAffiliateStatsQuery>;
export type GetAllDemandsForAffiliateStatsLazyQueryHookResult = ReturnType<typeof useGetAllDemandsForAffiliateStatsLazyQuery>;
export type GetAllDemandsForAffiliateStatsQueryResult = Apollo.QueryResult<GetAllDemandsForAffiliateStatsQuery, GetAllDemandsForAffiliateStatsQueryVariables>;
export const AllMessagesDocument = gql`
    query allMessages {
  getAllMessages {
    message
    _id
    senderPhoneNumber
    createdAt
    sender
    isRegistred
    isPrestataire
    accountId
  }
}
    `;

/**
 * __useAllMessagesQuery__
 *
 * To run a query within a React component, call `useAllMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllMessagesQuery(baseOptions?: Apollo.QueryHookOptions<AllMessagesQuery, AllMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllMessagesQuery, AllMessagesQueryVariables>(AllMessagesDocument, options);
      }
export function useAllMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllMessagesQuery, AllMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllMessagesQuery, AllMessagesQueryVariables>(AllMessagesDocument, options);
        }
export type AllMessagesQueryHookResult = ReturnType<typeof useAllMessagesQuery>;
export type AllMessagesLazyQueryHookResult = ReturnType<typeof useAllMessagesLazyQuery>;
export type AllMessagesQueryResult = Apollo.QueryResult<AllMessagesQuery, AllMessagesQueryVariables>;
export const GetAllPaientsDocument = gql`
    query GetAllPaients($search: PatientSearchInput!) {
  getAllPaients(search: $search) {
    id
    nom
    prenom
    nbrDemandePasser
    photo
    dateNaissance
    ville
    pay
    cin
    sexe
    email
    phone
    createdAt
  }
}
    `;

/**
 * __useGetAllPaientsQuery__
 *
 * To run a query within a React component, call `useGetAllPaientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPaientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPaientsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetAllPaientsQuery(baseOptions: Apollo.QueryHookOptions<GetAllPaientsQuery, GetAllPaientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPaientsQuery, GetAllPaientsQueryVariables>(GetAllPaientsDocument, options);
      }
export function useGetAllPaientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPaientsQuery, GetAllPaientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPaientsQuery, GetAllPaientsQueryVariables>(GetAllPaientsDocument, options);
        }
export type GetAllPaientsQueryHookResult = ReturnType<typeof useGetAllPaientsQuery>;
export type GetAllPaientsLazyQueryHookResult = ReturnType<typeof useGetAllPaientsLazyQuery>;
export type GetAllPaientsQueryResult = Apollo.QueryResult<GetAllPaientsQuery, GetAllPaientsQueryVariables>;
export const GetAdminPaientByIdDocument = gql`
    query GetAdminPaientById($id: String!) {
  getAdminPatientById(patientId: $id) {
    id
    nom
    prenom
    nbrDemandePasser
    photo
    dateNaissance
    ville
    pay
    cin
    sexe
    email
    phone
    address {
      adresse
      latitude
      longitude
    }
  }
}
    `;

/**
 * __useGetAdminPaientByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminPaientByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminPaientByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminPaientByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminPaientByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAdminPaientByIdQuery, GetAdminPaientByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminPaientByIdQuery, GetAdminPaientByIdQueryVariables>(GetAdminPaientByIdDocument, options);
      }
export function useGetAdminPaientByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminPaientByIdQuery, GetAdminPaientByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminPaientByIdQuery, GetAdminPaientByIdQueryVariables>(GetAdminPaientByIdDocument, options);
        }
export type GetAdminPaientByIdQueryHookResult = ReturnType<typeof useGetAdminPaientByIdQuery>;
export type GetAdminPaientByIdLazyQueryHookResult = ReturnType<typeof useGetAdminPaientByIdLazyQuery>;
export type GetAdminPaientByIdQueryResult = Apollo.QueryResult<GetAdminPaientByIdQuery, GetAdminPaientByIdQueryVariables>;
export const GetPatientDemandsDocument = gql`
    query getPatientDemands($patientId: String!) {
  getPatientDemands(patientId: $patientId) {
    id
    patientId {
      id
      nom
      prenom
    }
    status
    montant
    dateSeance
    adressePatient
    prestataireId {
      id
      InfirPrenom
      nom
    }
    description
    modePaiment
    calculatedAmount
    AppliedDiscountAmount
    AppliedDiscountPercentage
    createdAt
    cashPlusCode
    isPayed
    offerSerice {
      id
      group
      serviceName
    }
  }
}
    `;

/**
 * __useGetPatientDemandsQuery__
 *
 * To run a query within a React component, call `useGetPatientDemandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientDemandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientDemandsQuery({
 *   variables: {
 *      patientId: // value for 'patientId'
 *   },
 * });
 */
export function useGetPatientDemandsQuery(baseOptions: Apollo.QueryHookOptions<GetPatientDemandsQuery, GetPatientDemandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPatientDemandsQuery, GetPatientDemandsQueryVariables>(GetPatientDemandsDocument, options);
      }
export function useGetPatientDemandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPatientDemandsQuery, GetPatientDemandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPatientDemandsQuery, GetPatientDemandsQueryVariables>(GetPatientDemandsDocument, options);
        }
export type GetPatientDemandsQueryHookResult = ReturnType<typeof useGetPatientDemandsQuery>;
export type GetPatientDemandsLazyQueryHookResult = ReturnType<typeof useGetPatientDemandsLazyQuery>;
export type GetPatientDemandsQueryResult = Apollo.QueryResult<GetPatientDemandsQuery, GetPatientDemandsQueryVariables>;
export const GetAllPrestataireDocument = gql`
    query GetAllPrestataire($search: PrestatireSearchInput) {
  getAllPrestataire(search: $search) {
    id
    phone
    nom
    email
    Pays
    adresse
    ville
    photo
    nbrDemandetraiter
    Dateinscription
    Balance
    Offres {
      group
      prixService
      serviceName
    }
    InfirPrenom
    InfirDiplome
    InfirSexe
    InfirCinChemin
    infirRib
    InfirDiplomeChemin
    dateNaissance
    createdAt
    infirFonction
    InfirCin
    Secteurs {
      id
      secteurFR
    }
    status
    nbrReviews
    infirExperiences
    messages {
      message
      vue
      sender
      senderPhoneNumber
      createdAt
    }
  }
}
    `;

/**
 * __useGetAllPrestataireQuery__
 *
 * To run a query within a React component, call `useGetAllPrestataireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPrestataireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPrestataireQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetAllPrestataireQuery(baseOptions?: Apollo.QueryHookOptions<GetAllPrestataireQuery, GetAllPrestataireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllPrestataireQuery, GetAllPrestataireQueryVariables>(GetAllPrestataireDocument, options);
      }
export function useGetAllPrestataireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllPrestataireQuery, GetAllPrestataireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllPrestataireQuery, GetAllPrestataireQueryVariables>(GetAllPrestataireDocument, options);
        }
export type GetAllPrestataireQueryHookResult = ReturnType<typeof useGetAllPrestataireQuery>;
export type GetAllPrestataireLazyQueryHookResult = ReturnType<typeof useGetAllPrestataireLazyQuery>;
export type GetAllPrestataireQueryResult = Apollo.QueryResult<GetAllPrestataireQuery, GetAllPrestataireQueryVariables>;
export const GetAdminPrestataireByIdDocument = gql`
    query getAdminPrestataireById($id: String!) {
  getAdminPrestataireById(PrestataireId: $id) {
    id
    phone
    nom
    email
    Pays
    adresse
    ville
    photo
    nbrDemandetraiter
    Dateinscription
    Balance
    InfirPrenom
    InfirDiplome
    InfirSexe
    InfirCinChemin
    InfirRib
    InfirDiplomeChemin
    dateNaissance
    infirFonction
    InfirCin
    status
    nbrReviews
    OffresServices {
      prestataireId
      group
      serviceName
      prixService
      discounts {
        discount
        nmbr
      }
    }
    dispo {
      program {
        Jour
        EndTime
        StartTime
      }
    }
    Secteurs {
      secteurFR
    }
  }
}
    `;

/**
 * __useGetAdminPrestataireByIdQuery__
 *
 * To run a query within a React component, call `useGetAdminPrestataireByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminPrestataireByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminPrestataireByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminPrestataireByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAdminPrestataireByIdQuery, GetAdminPrestataireByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminPrestataireByIdQuery, GetAdminPrestataireByIdQueryVariables>(GetAdminPrestataireByIdDocument, options);
      }
export function useGetAdminPrestataireByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminPrestataireByIdQuery, GetAdminPrestataireByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminPrestataireByIdQuery, GetAdminPrestataireByIdQueryVariables>(GetAdminPrestataireByIdDocument, options);
        }
export type GetAdminPrestataireByIdQueryHookResult = ReturnType<typeof useGetAdminPrestataireByIdQuery>;
export type GetAdminPrestataireByIdLazyQueryHookResult = ReturnType<typeof useGetAdminPrestataireByIdLazyQuery>;
export type GetAdminPrestataireByIdQueryResult = Apollo.QueryResult<GetAdminPrestataireByIdQuery, GetAdminPrestataireByIdQueryVariables>;
export const UpdatePrestatireInfoDocument = gql`
    mutation updatePrestatireInfo($data: updatePrestatireInfo!, $id: String!) {
  updatePrestatireInfoAdmin(data: $data, id: $id)
}
    `;
export type UpdatePrestatireInfoMutationFn = Apollo.MutationFunction<UpdatePrestatireInfoMutation, UpdatePrestatireInfoMutationVariables>;

/**
 * __useUpdatePrestatireInfoMutation__
 *
 * To run a mutation, you first call `useUpdatePrestatireInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrestatireInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrestatireInfoMutation, { data, loading, error }] = useUpdatePrestatireInfoMutation({
 *   variables: {
 *      data: // value for 'data'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdatePrestatireInfoMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrestatireInfoMutation, UpdatePrestatireInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrestatireInfoMutation, UpdatePrestatireInfoMutationVariables>(UpdatePrestatireInfoDocument, options);
      }
export type UpdatePrestatireInfoMutationHookResult = ReturnType<typeof useUpdatePrestatireInfoMutation>;
export type UpdatePrestatireInfoMutationResult = Apollo.MutationResult<UpdatePrestatireInfoMutation>;
export type UpdatePrestatireInfoMutationOptions = Apollo.BaseMutationOptions<UpdatePrestatireInfoMutation, UpdatePrestatireInfoMutationVariables>;
export const GetPrestatireDemandsDocument = gql`
    query getPrestatireDemands($prestatiareId: String!) {
  getPrestatireDemands(prestataireId: $prestatiareId) {
    id
    patientId {
      id
      nom
      prenom
    }
    status
    montant
    dateSeance
    adressePatient
    prestataireId {
      id
      InfirPrenom
      nom
    }
    description
    modePaiment
    calculatedAmount
    AppliedDiscountAmount
    AppliedDiscountPercentage
    createdAt
    cashPlusCode
    isPayed
    offerSerice {
      id
      group
      serviceName
    }
  }
}
    `;

/**
 * __useGetPrestatireDemandsQuery__
 *
 * To run a query within a React component, call `useGetPrestatireDemandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrestatireDemandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrestatireDemandsQuery({
 *   variables: {
 *      prestatiareId: // value for 'prestatiareId'
 *   },
 * });
 */
export function useGetPrestatireDemandsQuery(baseOptions: Apollo.QueryHookOptions<GetPrestatireDemandsQuery, GetPrestatireDemandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrestatireDemandsQuery, GetPrestatireDemandsQueryVariables>(GetPrestatireDemandsDocument, options);
      }
export function useGetPrestatireDemandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrestatireDemandsQuery, GetPrestatireDemandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrestatireDemandsQuery, GetPrestatireDemandsQueryVariables>(GetPrestatireDemandsDocument, options);
        }
export type GetPrestatireDemandsQueryHookResult = ReturnType<typeof useGetPrestatireDemandsQuery>;
export type GetPrestatireDemandsLazyQueryHookResult = ReturnType<typeof useGetPrestatireDemandsLazyQuery>;
export type GetPrestatireDemandsQueryResult = Apollo.QueryResult<GetPrestatireDemandsQuery, GetPrestatireDemandsQueryVariables>;
export const GelAlLsecturesDocument = gql`
    query gelALLsectures($id: String!) {
  getAllSecteuresByVilleId(villeId: $id) {
    id
    secteurFR
  }
}
    `;

/**
 * __useGelAlLsecturesQuery__
 *
 * To run a query within a React component, call `useGelAlLsecturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGelAlLsecturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGelAlLsecturesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGelAlLsecturesQuery(baseOptions: Apollo.QueryHookOptions<GelAlLsecturesQuery, GelAlLsecturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GelAlLsecturesQuery, GelAlLsecturesQueryVariables>(GelAlLsecturesDocument, options);
      }
export function useGelAlLsecturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GelAlLsecturesQuery, GelAlLsecturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GelAlLsecturesQuery, GelAlLsecturesQueryVariables>(GelAlLsecturesDocument, options);
        }
export type GelAlLsecturesQueryHookResult = ReturnType<typeof useGelAlLsecturesQuery>;
export type GelAlLsecturesLazyQueryHookResult = ReturnType<typeof useGelAlLsecturesLazyQuery>;
export type GelAlLsecturesQueryResult = Apollo.QueryResult<GelAlLsecturesQuery, GelAlLsecturesQueryVariables>;
export const GetAllServiceAndOptionDocument = gql`
    query GetAllServiceAndOption {
  getAllServiceAndOption {
    group
  }
}
    `;

/**
 * __useGetAllServiceAndOptionQuery__
 *
 * To run a query within a React component, call `useGetAllServiceAndOptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllServiceAndOptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllServiceAndOptionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllServiceAndOptionQuery(baseOptions?: Apollo.QueryHookOptions<GetAllServiceAndOptionQuery, GetAllServiceAndOptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllServiceAndOptionQuery, GetAllServiceAndOptionQueryVariables>(GetAllServiceAndOptionDocument, options);
      }
export function useGetAllServiceAndOptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllServiceAndOptionQuery, GetAllServiceAndOptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllServiceAndOptionQuery, GetAllServiceAndOptionQueryVariables>(GetAllServiceAndOptionDocument, options);
        }
export type GetAllServiceAndOptionQueryHookResult = ReturnType<typeof useGetAllServiceAndOptionQuery>;
export type GetAllServiceAndOptionLazyQueryHookResult = ReturnType<typeof useGetAllServiceAndOptionLazyQuery>;
export type GetAllServiceAndOptionQueryResult = Apollo.QueryResult<GetAllServiceAndOptionQuery, GetAllServiceAndOptionQueryVariables>;