import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, Observable } from "@apollo/client";
import { onError } from "apollo-link-error";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import jwtDecode from "jwt-decode";
import { API_URL } from "../constant/api";
import { getAccessToken, setAccessToken } from "./accessToken";
import { createNetworkStatusNotifier } from "react-apollo-network-status";

export const { link, useApolloNetworkStatus } = createNetworkStatusNotifier();

const cache = new InMemoryCache({});

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      console.log(observer);

      let handle: any;
      Promise.resolve(operation)
        .then(operation => {
          const accessToken = getAccessToken();
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `${accessToken}`,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

export const client = (onErrorCallback?: VoidFunction) => {
  return new ApolloClient({
    link: link.concat(
      ApolloLink.from([
        new TokenRefreshLink({
          accessTokenField: "accesToken",
          isTokenValidOrUndefined: () => {
            const token = getAccessToken();

            if (!token) {
              return true;
            }

            try {
              const { exp } = jwtDecode(token) as any;
              console.log("exp", exp);

              if (Date.now() >= exp * 1000) {
                return false;
              } else {
                return true;
              }
            } catch {
              return false;
            }
          },
          fetchAccessToken: () => {
            return fetch(`${API_URL}user/refresh_token`, {
              method: "GET",
              credentials: "include",
            });
          },
          handleFetch: accessToken => {
            setAccessToken(accessToken);
          },
          handleError: err => {
            console.warn("Your refresh token is invalid. Try to relogin");
            console.error(err);
            onErrorCallback && onErrorCallback();
          },
        }),

        onError(({ graphQLErrors, networkError }) => {
          onErrorCallback && onErrorCallback();
          console.log(graphQLErrors);
          console.log(networkError);

          if (graphQLErrors || networkError) {
            console.log("errrrrrrrrrrror", graphQLErrors, networkError);
          }
        }) as any,

        requestLink,
        new HttpLink({
          uri: `${API_URL}graphql`,
          credentials: "include",
        }),
      ])
    ),
    cache,
  });
};
