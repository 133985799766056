import React from "react";
import ReactDom from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Router, useNavigate } from "react-router";
import "antd/dist/reset.css";
import { ApolloProvider } from "@apollo/client";

import App from "./App";
import "./styles/index.css";
import { DrawerProvider } from "./context/Drawer";
import { AuthProvider } from "./context/Auth";
import { client } from "./utils/ApolloClient";
import LoadingProvider from "./context/Loading";
import { useLoading } from "./hooks/useLoading";

// Application  Root
const AppRoot = () => {
  const navigate = useNavigate();
  const { setIsLoading } = useLoading();
  const apolloClient = client(() => navigate("/login"));
  return (
    <React.StrictMode>
      <LoadingProvider>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ApolloProvider>
      </LoadingProvider>
    </React.StrictMode>
  );
};

const documentRoot = document.getElementById("root");

ReactDom.render(
  <BrowserRouter>
    <AppRoot />
  </BrowserRouter>,
  documentRoot
);
